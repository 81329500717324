import React from 'react';
import { CardDetailsTable, EmptyTable, SimpleTable } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';


function PaymentDetails(props) {


    const [selectedZone, setselectedZone] = React.useState(0);

    const saveChanges = (accordian) => {
        console.log("data", accordian)
        setselectedZone(0)
    }
    const onAccordianClick = (panel) => {
        console.log("onAccordianClick", panel)
        if (panel === selectedZone) {
            setselectedZone(0)
        } else {
            setselectedZone(panel)
        }
    }

    const AccordionDetails = {
        top: "0px"
        //top:"-46px"
    };



    let header = {
        tableName: `Payment Details`,
    };

    let data = [
        {
            field: "Students",
            value: "2"
        },

        {
            field: "Students",
            value: "2"
        },

        {
            field: "Total",
            value: "$12800"
        },


    ];

    let data2 = [
        {
            field: "Payment Method",
            value: "Hassan Ahmed"
        },
        {
            field: "Payment Status",
            value: "Pending",
        },

        {
            field: "Balance",
            value: "$3200",
        },

    ];

    let footerLinkName = "";
    let footerDeleteButton = "";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('/edit-detail')
    }

    const onDeleteClick = () => {
        console.log('cancel-membership')
    }


    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };

    const style = {
        width: '100%',
    };



    const back = () => {
        console.log("back");
    }



    const Statustdata2 = [
        { value: 1, statuscolor: "#7266FB", select: "New Order" },
        { value: 2, statuscolor: "#FF9800", select: "In-progress" },
        { value: 3, statuscolor: "#3CC480", select: "Completed" },
        { value: 4, statuscolor: "#818E94", select: "On hold" },
        { value: 5, statuscolor: "#C44058", select: "Canceled" },
    ];


    const history = useHistory();





    // ============================== Table Start ======================================//
    let col = [

        { type: 'link', name: 'invoice_number', label: 'AMOUNT', width: '15%' },
        { type: 'string', name: 'date', label: 'INVOICE NUMBER', width: '15%' },
        { type: 'string', name: 'frequency', label: 'INVOICE DATE', width: '20%' },
        { type: 'string', name: 'frequency', label: 'PAYMENT METHOD', width: '15%' },
        { type: 'string', name: 'frequency', label: 'PAYMENT', width: '10%' },
        { type: 'action', label: 'ACTION', width: '10%' }

    ];

    const selected_categories = col.sort((a, b) => a.order - b.order)
    let rows = selected_categories.map((text, index) => {
        let i = index + 1
        return (
            [
                { type: 'string', name: 'name', value: '$1200' },
                {
                    type: 'link',
                    name: 'invoice_number',
                    value: '1848-5131',
                    url: 'https://www.google.com',
                    linkType: "external",
                },

                { type: 'string', name: 'date', value: 'Oct 1, 2021' },
                { type: 'string', name: 'frequency', value: 'Credit Card' },
                { type: 'status', name: 'string', value: 'Pending' },
                { type: 'action', status: 'success', value: 'VIEW INVOICE' },

            ]
        )
    })


    // ============================== Table End ======================================//


    return (



        <Grid container spacing={3}>

            <Grid item xs={12}>
                <CardDetailsTable
                    data={data}
                    data2={data2}
                    twoColumns
                    header={header}
                    footerLinkName={footerLinkName}
                    footerDeleteButton={footerDeleteButton}
                    onClick={() => onClick()}
                    onDeleteClick={() => onDeleteClick()}


                    handleChange="handleChange"
                    value="2"
                    Statustdata2={Statustdata2}
                    StatusDropdown={false}


                    HeaderStatus={false}

                />
            </Grid>

            <Grid item xs={12}>
                <EmptyTable
                    title="Payment history"
                    onAddClick={() => history.replace("/education/add-payment")}
                    addLabel="ADD PAYMENT"
                    noBtnText="ADD PAYMENT"

                />
            </Grid>

            <Grid item xs={12}>
                <div className="table_box">
                    <SimpleTable
                        showHeader={true}
                        title="Payment history"
                        width="100%"
                        rows={rows}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        donationsCount={rows && rows.length > 0 ? 1 : 0}
                        noTitle="No inquiry submitted yet"
                        noPara="Inquiries submitted by visitors will be visible and managed here."
                        noBtnText=""
                        onInviteClick={() =>
                            props.history.push(
                                "/education/order-details"
                            )
                        }
                        actionLink={(name) => props.history.push({ pathname: "/education/order-details", state: { contact: name[0].data } })}
                        showIconCol={false}
                        deleteAction={(e) => console.log(e)}
                        showFooter={true}
                        showAdd={true}
                        onAddClick={() => history.push("/education/add-payment")}
                        addText='ADD PAYMENT'
                    />
                </div>
            </Grid>

        </Grid>





    );
}


export default PaymentDetails;