import React from 'react';
import { CardDetailsTable, Initials, CardStatus } from "@dclab/dc-react-ui-kit";

function DeceasedDetails (props) {
   let header = {
        tableName: `Deceased Details`,
    };

    let data = [
        {
            field: "Name",
            value: "Shayan"
        },
        
        {
            field: "Age",
            value: "50"
        },
        
        {
            field: "Gender",
            value: "Male"
        },
        {
            field: "Country Of Birth",
            value: "United States"
        },
        {
            field: "City, State",
            value: "Fort Pierce, Florida"
        },
        
        
    ];

    let data2 = [
        {
            field: "Details Of Death",
            value: "When Muslims talk about God, they automatically think of Allah. Allah is the Arabic word for “The One True God”. He is the Almighty, the Creator who created the universe, mankind and all other of life."
        },
    ];

    

    let footerLinkName = "";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('edit')
    }

    const onDeleteClick = () => {
        console.log('delete')
    }

    const onClickSend = () => {
        console.log('Send')
    }


    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };


    return (

        <CardDetailsTable
            data={data}
            data2={data2} 
            twoColumns 
            header={header}
            footerLinkName={footerLinkName}
            onClick={() => onClick()}
            onDeleteClick={() => onDeleteClick()}

            Status={false}
            StatusLabel="Status"
            StatusText="Active"
            statuscolor="#3CC480"

            OrgName="Deenfund"
            OrgSubText="Application"
            HeaderStatus={false}

        />

    );
}


export default DeceasedDetails;