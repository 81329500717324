import React from 'react';
import { SimpleAccordian, } from "@dclab/dc-react-ui-kit";
import NumberOfSeats from './NumberOfSeats';
import AgeGroup from './AgeGroup';
import GradeLevel from './GradeLevel';






function Learners(props) {


    const [selectedZone, setselectedZone] = React.useState(0);

    const saveChanges = (accordian) => {
        console.log("data", accordian)
        setselectedZone(0)
    }
    const onAccordianClick = (panel) => {
        console.log("onAccordianClick", panel)
        if (panel === selectedZone) {
            setselectedZone(0)
        } else {
            setselectedZone(panel)
        }
    }

    const AccordionDetails = {
        top: "0px"
        //top:"-46px"
    };





    const style = {
        width: '100%',
    };



    const back = () => {
        console.log("back");
    }





    const eData = [
        {
            id: 1,
            title: "NUMBER OF SEATS",
            subtext: "Guests attending",
            Edit: "EDIT",
            save: "SAVE CHANGES",
            body: <NumberOfSeats />,
        },

        {
            id: 2,
            title: "AGE GROUP",
            subtext: "All age groups",
            Edit: "EDIT",
            save: "SAVE CHANGES",
            body: <AgeGroup />,
        },

        {
            id: 3,
            title: "GRADE LEVEL",
            subtext: "Not applicable",
            Edit: "EDIT",
            save: "SAVE CHANGES",
            body: <GradeLevel />,
        },


    ];


    return (

        <div className='acdAcord'>

            {eData.map((item, i) => (
                <SimpleAccordian
                    Title={item.title}
                    text={item.subtext}
                    TitleButtonLabel={item.Edit}
                    AccordionDetails={AccordionDetails}
                    panel={item.id}
                    id={item.id}
                    areacontrol={item.id}
                    open={selectedZone === item.id ? true : false}
                    onAccordianClick={onAccordianClick}
                    accordianName="headerCard"
                    saveChanges={item.save}
                    Cencel="CANCEL"
                    Save={item.save}
                    color={item.color}
                    fontSize={item.size}
                    content={
                        <div className='AccformBox'>
                            {item.body}
                        </div>
                    }
                />
            ))}

        </div>



    );
}


export default Learners;