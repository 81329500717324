import React from 'react';
import {
    SimpleTable,
    MasterLayoutMiddle,
    FooterInner
} from '@dclab/dc-react-ui-kit';

import UsFlag from '../../../assets/img/united-states.svg';


function Promotions(props) {

    const style = {
        width: '100%',
    };



    // ============================== Table Start ======================================//
    let col = [

        { type: 'link', name: 'invoice_number', label: 'CODE', width: '25%' },
        { type: 'string', name: 'date', label: 'TERMS', width: '15%' },
        { type: 'string', name: 'frequency', label: 'REDEMPTIONS', width: '15%' },
        { type: 'string', name: 'frequency', label: 'EXPIRES', width: '15%' },
        { type: 'string', name: 'frequency', label: 'STATUS', width: '10%' },
        { type: 'action', label: 'ACTION', width: '10%' }

    ];

    const selected_categories = col.sort((a, b) => a.order - b.order)
    let rows = selected_categories.map((text, index) => {
        let i = index + 1
        return (
            [

                {
                    type: 'link',
                    name: 'invoice_number',
                    value: 'EID2021',
                    url: 'https://www.google.com',
                    linkType: "external",
                    stripe: "Stripe",
                    display: "inline-block",
                },

                { type: 'string', name: 'date', value: '20% off once' },
                { type: 'string', name: 'frequency', value: '0/10' },
                { type: 'string', name: 'frequency', value: 'Sep 12, 2020, 12:00 AM' },
                { type: 'status', name: 'string', value: 'Active' },
                { type: 'action', status: 'success', value: 'see details' },

            ]
        )
    })


    // ============================== Table End ======================================//

    return (

        <MasterLayoutMiddle
            header={
                <>

                </>
            }
            content={
                <div className="table_box">
                    <SimpleTable
                        stripe={true}
                        showHeader={true}
                        title="Promotions"
                        width="100%"
                        rows={rows}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        donationsCount={rows && rows.length > 0 ? 1 : 0}
                        noTitle="No inquiry submitted yet"
                        noPara="Inquiries submitted by visitors will be visible and managed here."
                        noBtnText=""
                        onInviteClick={() =>
                            props.history.push(
                                "/education/order-details"
                            )
                        }
                        actionLink={(name) => props.history.push({ pathname: "/education/promotions/add-new-campaign", state: { contact: name[0].data } })}
                        showIconCol={false}
                        deleteAction={(e) => console.log(e)}
                        showFooter={true}
                        showFilter={true}
                        filterText='Filter'
                        onAddClick={() => props.history.push("/education/promotions/add-new-campaign")}
                        showAdd={true}
                        addText='ADD NEW'
                    />
                </div>
            }
            isFooter={true}
            footer={
                <FooterInner
                    style={style}
                    termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
                    privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                />
            }
            isWhiteBackground={false}
        />

    );
}


export default Promotions;