import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  SimpleAppBar,
  BasePaper,
  NavDrawer,
  BaseIcon,
  MasterLayout,
} from "@dclab/dc-react-ui-kit";
import { Switch, Route, withRouter } from "react-router-dom";
import DonorCollection from "./donorCollection";
import DashboardPage from "../../components/dashboard";
import DashboardStats from "../../components/dashboardStats";
import Dk from "../../components/dk";
import Announcements from "./deen-kioks-website/Announcements";
import EventsComponent from "./deen-kioks-website/Events";
import AddSalah from "./deen-kioks-website/addSalah";
import EditSalah from "./deen-kioks-website/editSalah";
import SalahSettings from "./deen-kioks-website/salahSettings";
import Managesalah from "./deen-kioks-website/ManageSalah";
import Managereminders from "./deen-kioks-website/ManageReminders";
import Manageannoucements from "./deen-kioks-website/ManageAnnoucements";
import AddReminders from "./deen-kioks-website/AddReminders";
import EditReminder from "./deen-kioks-website/editReminders";
import AddAnnoucement from "./deen-kioks-website/addAnnoucement";
import EditAnnoucement from "./deen-kioks-website/editAnnoucements";
import AddEvent from "./deen-kioks-website/addEvent";
import EditEvent from "./deen-kioks-website/editEvent";
import Editpofile from "../UserProfile/Editpofile";
import AdminProfile from "../UserProfile/AdminProfile";
import ManageApps from "../ManageApps/ManageApps";
import MoreAppDetails from "../ManageApps/AppDetails";
import MoreAppsCartDetails from "../ManageApps/MoreAppsCartDetails";
import Messages from "../Messages/Messages";
//nav icons list
import { ReactComponent as Dashboard } from "../../assets/nav_icons/dashboard-24px.svg";
import { ReactComponent as Message } from "../../assets/nav_icons/message.svg";
import { ReactComponent as AnnouncementIcon } from "../../assets/nav_icons/announcement.svg";
import { ReactComponent as Reminder } from "../../assets/nav_icons/schedule-24px.svg";
import { ReactComponent as Salah } from "../../assets/nav_icons/salah.svg";
import { ReactComponent as Events } from "../../assets/nav_icons/events.svg";
import { ReactComponent as Donation } from "../../assets/nav_icons/monetization.svg";
import { ReactComponent as Media } from "../../assets/nav_icons/medialibrary.svg";
import { ReactComponent as Website } from "../../assets/nav_icons/website.svg";
import { ReactComponent as Deenfund } from "../../assets/nav_icons/deenfund.svg";
import { ReactComponent as Deenkiosk } from "../../assets/nav_icons/deenkiosk.svg";
import { ReactComponent as Organization } from "../../assets/nav_icons/orgranization.svg";
import { ReactComponent as Admin } from "../../assets/nav_icons/admin.svg";
import { ReactComponent as MoreApps } from "../../assets/nav_icons/moreapps.svg";
import { ReactComponent as Support } from "../../assets/nav_icons/support.svg";
import { ReactComponent as Launch } from "../../assets/nav_icons/launch-24px.svg";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { ChevronRight, SubscriptionsTwoTone } from "@material-ui/icons";
import MainLogo from "../../assets/img/DC_Logo.png";
import { getOrganizationData, getNewMessageCount } from "../Organization/api";
import "../../App.css";
import ContactUs from "../Messages/contact-us";
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import { ReactComponent as orderIcon } from "../../assets/nav_icons/orderIcon.svg";

import BuildProject from "../BuildProject/BuildProject";
import AddTestimonial from "../BuildProject/AddTestimonial";
import AddCommunitySupporter from "../BuildProject/AddCommunitySupporter";
import OrganizationDetailsForm from "../BuildProject/OrganizationDetailsForm";
import ProjectDescription from "../BuildProject/ProjectDescription";
import PostUpdate from "../BuildProject/PostUpdate";
import EditUpdate from "../BuildProject/EditUpdate";
import AddGuest from "../BuildProject/AddGuest";
import TestimonialListing from "../BuildProject/TestimonialListing";
import CommunitySupportListing from "../BuildProject/CommunitySupportListing";
import ProjectHighlights from "../BuildProject/ProjectHighlights";
import GuestsListing from "../BuildProject/GuestsListing";
import ProjectPhase from "../BuildProject/ProjectPhase";

import { getCurrentUserDetail, getPlanSubscriptionsByOrgId } from "../UserProfile/Apis/index";

import ManageSubscriptions from "../ManageSubscriptions/ManageSubscriptions";
import ManageSubscriptionsDetails from "../ManageSubscriptions/ManageSubscriptionsDetails";

// import Order from "../Order/Order";
// import OrderDetails from "../Order/OrderDetails";
import ManageOrder from "../Order/ManageOrder";

import MediaLibrary from "../MediaLibrary/MediaLibrary";
import OrgOrders from "../Organization/Orders/Index";
import OrderDetails from "../Organization/Orders/OrderDetails";
import ManageOrders from "../Organization/Orders/ManageOrder";
import PlanDetails from "../Organization/Plans/PlanDetails";

import SessionExpiredModal from "../../components/SessionExpiredModal";
import PersonIcon from '@material-ui/icons/Person';
import ForumIcon from '@material-ui/icons/Forum';
import PrivateRoute from '../../components/PrivateRoute';

import ManageSubscriptionsIcon from "../../assets/img/manage_accounts.svg";


// === Jumah Pages Start ===//

import JumahSalah from '../Jumah/JumahSalah';
import AddNewJumah from '../Jumah/AddNewJumah';
import JumahDetails from '../Jumah/JumahDetails';



// === Jumah Pages End ===//


const HomePage = (props) => {
  const [orgData, setOrgData] = useState([]);
  const [error, setError] = useState(false);
  const [detailsRequired, setDetailsRequired] = useState(true);
  const [stripeRequired, setStripeRequired] = useState(true);
  const [taxStatusRequired, settaxStatusRequired] = useState(false)
  const [loading, setLoading] = useState(true);
  const [subscriptions, setsubscriptions] = useState([])
  const [displayName, setDisplayName] = useState({});
  const [messageCount, setMessageCount] = useState(0);
  const [taxIdEinRequired, setTaxIdEinRequired] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname])

  useEffect(() => {
    const fetchData = async () => {
      const orgId = localStorage.getItem("orgID");
      const token = localStorage.getItem("authToken");
      try {
        const msgCount = await getNewMessageCount(orgId, token);
        console.log("msgCount?.data?.body?.data: ", msgCount?.data?.body?.data);
        if (msgCount?.data?.body?.data)
          setMessageCount(msgCount.data.body.data)
        const res = await getOrganizationData(orgId);
        // console.log(res, 'dashboard')
        const response = res?.data?.body?.data;
        const { phoneFax, enabledIntegrations, orgAddress, taxIdStatus, orgShortName } = response;

        if (
          (phoneFax && phoneFax.primaryPhone.length < 3) &&
          (!orgShortName) &&
          (orgAddress && orgAddress.line1.length < 3) &&
          (orgAddress &&
            ((orgAddress.city && orgAddress.city.length === 0) ||
              (orgAddress.country && orgAddress.country.length === 0))) &&
          (orgAddress && orgAddress?.state?.length === 0)
        ) {
          setDetailsRequired(true);
          setLoading(false);
        }
        if (
          (phoneFax && phoneFax.primaryPhone.length > 3) &&
          orgShortName &&
          (orgAddress && orgAddress.line1.length > 3) &&
          (orgAddress &&
            ((orgAddress.city && orgAddress.city.length) ||
              orgAddress.county.length)) &&
          (orgAddress && orgAddress.state.length)
        ) {
          setDetailsRequired(false);
          setLoading(false);
        }
        if (!enabledIntegrations.deenfund.stripeAccount) {
          setStripeRequired(true);
          setLoading(false);
        }
        if (enabledIntegrations.deenfund.stripeAccount) {
          setStripeRequired(false);
          setLoading(false);
        }

        if (taxIdStatus?.taxExempt) {
          settaxStatusRequired(true)
          setLoading(false);
        }
        // if (taxIdStatus?.taxExempt && !taxIdStatus?.taxIdEin) {
        //   settaxStatusRequired(true)
        //   setLoading(false);
        // }
        if (taxIdStatus?.taxIdEin && taxIdStatus?.taxIdEin?.length > 0) {
          setTaxIdEinRequired(false)
          // settaxStatusRequired(false)
          setLoading(false);
        }
        setOrgData(response);

        const subscriptionRes = await getPlanSubscriptionsByOrgId(orgId, token);
        if (subscriptionRes?._id) {
          let subsData = subscriptionRes;
          let tempDisplayName = {};
          const arr = Object.keys(subsData)
            .filter(v => {
              return subsData[v] != null && typeof subsData[v] === 'object'
            }).map(key => ({ ...subsData[key], planName: [key][0] }))

          let subs = [];
          arr?.length && arr.forEach((el) => {
            if (el.planName?.toLowerCase() === "extraintegrations") {
              el.selectedIntegrations?.length && el.selectedIntegrations.forEach(int => {
                subs.push(int?.integrationApi?.toLowerCase());
              });
            } else {
              if (el.plan) {
                subs.push(el?.planName?.toLowerCase());
                el.planData?.length && el.planData.forEach(feature => {
                  subs.push(feature?.name?.toLowerCase());
                });
                if (el.displayName) {
                  tempDisplayName[el?.planName?.toLowerCase()] = el.displayName;
                }
              }

            }
          });
          // console.log("subs", subs)
          setDisplayName(tempDisplayName);
          setsubscriptions(subs);
        }
      } catch (error) {
        console.log("Error Occured: ", error)
        setError(error);
        setLoading(false);
        setTimeout(() => {
          setError(false)
        }, 8000);
      }
    };
    fetchData();
  }, []);

  const styles = {
    conatiner: {
      display: "flex",
    },
    navBar: {
      backgroundImage: "linear-gradient(#7266FB, #2490F9,#7266FB )",
      color: "white",
      zIndex: 200,
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
      minHeight: "100%",
      position: "relative",
    },
    paperStyle: {
      width: "100%",
      background: "FFF",
    },
    header: {
      background: "green",
      boxShadow: "0px 1px 4px #0000001A",
    },
    footer: {
      background: "FFF",
    },
  };

  const availableSubscriptions = (name) => {
    // console.log("subscriptions", subscriptions)
    if (name?.length && subscriptions?.length) {
      localStorage.setItem("subsData", JSON.stringify(subscriptions))
      const arr = name.filter(value => subscriptions.includes(value));
      return arr?.length ? true : false
    }
  };

  const returnActiveTab = () => {
    switch (props.match.path) {
      case '/editannoucement':
      case '/addannoucement':
        return "Announcements";

      case '/editreminder':
      case '/addreminder':
        return "Reminders";

      case '/editsalah':
      case '/addsalah':
      case '/salahsettings':
        return "Salah";

      case '/editevent/:id':
      case '/addevent':
        return "Events";

      case '/subscriber-details':
        return "Manage Subscriptions";

      case '/app-details':
      case '/organization/plan-details':
        return "More Apps";

      default:
        return false;

    }
  }

  const headerItems = [
    {
      title: "Dashboard",
      icon: <BaseIcon icon={Dashboard} />,
      path: "/dashboard",
    },
    // availableSubscriptions(["contact", "deenkiosk", "deenweb"]) ? 
    {
      title: "Messages",
      icon: <BaseIcon icon={Message} />,
      path: "/messages",
      count: messageCount ? messageCount : '',
      disabled: detailsRequired,
    }
    // : undefined
  ];
  const bodyItems = {
    manage: [
      availableSubscriptions(["announcements"]) ? {
        title: "Announcements",
        icon: <BaseIcon icon={AnnouncementIcon} />,
        path: "/annoucements",
        child: [],
        disabled: detailsRequired,
      } : undefined,
      availableSubscriptions(["reminders"]) ? {
        title: "Reminders",
        icon: <BaseIcon icon={Reminder} />,
        path: "/reminders",
        child: [],
        disabled: detailsRequired,
      } : undefined,
      availableSubscriptions(["salah"]) ? {
        title: "Salah",
        icon: <BaseIcon icon={Salah} />,
        path: "/salah",
        child: [],
        disabled: detailsRequired,
      } : undefined,
      availableSubscriptions(["salah/iqamah time"]) ? {
        title: "Salah",
        icon: <BaseIcon icon={Salah} />,
        path: "/salah",
        child: [],
        disabled: detailsRequired,
      } : undefined,

      // availableSubscriptions(["events"]) ? {
      //   title: "Jumu'ah",
      //   icon: <BaseIcon icon={Events} />,
      //   path: "/salat-al-jumah",
      //   child: [],
      //   disabled: detailsRequired,
      // } : undefined,

      availableSubscriptions(["events"]) ? {
        title: "Events",
        icon: <BaseIcon icon={Events} />,
        path: "/events",
        child: [],
        disabled: detailsRequired,
      } : undefined,

      availableSubscriptions(["guests"]) ? {
        title: "Guests",
        icon: <img src={ManageSubscriptionsIcon} />,
        path: "/organization/guests",
        child: [],
        disabled: detailsRequired,
      } : undefined,
      availableSubscriptions(["deenfund"]) ? {
        title: "Donations",
        icon: <BaseIcon icon={Donation} />,
        path: "/donations",
        child: [],
        disabled: detailsRequired,
      } : undefined,

      // availableSubscriptions(["build-project"]) ? {
      //   title: "Build Project",
      //   icon: <FiberManualRecordIcon icon={Donation} />,
      //   path: "/build-project",
      //   child: [],
      //   disabled: detailsRequired,
      // } : undefined,

      // availableSubscriptions(["orders"]) ? {
      //   title: "Orders",
      //   icon: <BaseIcon icon={orderIcon} />,
      //   path: "/orders",
      //   child: [],
      //   disabled: detailsRequired,
      // } : undefined,
      {
        title: "Media Library",
        icon: <BaseIcon icon={Media} />,
        path: "/media-library",
        child: [],
        disabled: detailsRequired,
      },
      {
        title: "Orders",
        icon: <BaseIcon icon={Media} />,
        path: "/orders",
        child: [],
        disabled: detailsRequired,
      },
      availableSubscriptions(["subscriptions"]) ?
        {
          title: "Manage Subscriptions",
          icon: <img src={ManageSubscriptionsIcon} />,
          path: "/manage-subscriptions",
          child: [],
          disabled: detailsRequired,
        } : undefined,
      availableSubscriptions(["communications"]) ? {
        title: "Communication",
        icon: <ForumIcon icon={Media} />,
        path: "/communication/messages",
        child: [],
        disabled: detailsRequired,
      } : undefined,

      // availableSubscriptions(["communications"]) ? {
      //   title: "Membership",
      //   icon: <ForumIcon icon={Media} />,
      //   path: "/membership/members",
      //   child: [],
      //   disabled: detailsRequired,
      // } : undefined,

      // availableSubscriptions(["communications"]) ? {
      //   title: "Nikah",
      //   icon: <ForumIcon icon={Media} />,
      //   path: "/nikah/nikah-request",
      //   child: [],
      //   disabled: detailsRequired,
      // } : undefined,

      // availableSubscriptions(["communications"]) ? {
      //   title: "Volunteer",
      //   icon: <ForumIcon icon={Media} />,
      //   path: "/volunteer/volunteer-request",
      //   child: [],
      //   disabled: detailsRequired,
      // } : undefined,

    ].filter((el) => el != null),
    applications: [
      availableSubscriptions(["deenweb"]) ? {
        title: displayName["deenweb"] ?? 'WEBSITE',
        icon: <BaseIcon icon={Website} />,
        path: "/website/announcements",
        child: [],
        disabled: detailsRequired,
      } : undefined,
      availableSubscriptions(["deenfund"]) ? {
        title: displayName["deenfund"] ?? 'DEENFUND',
        icon: <BaseIcon icon={Deenfund} />,
        path: "/deenfund/donations",
        secondaryIcon: <BaseIcon icon={ChevronRight} />,
        child: [],
        disabled: detailsRequired,
      } : undefined,

      availableSubscriptions(["deenkiosk"]) ? { //availableSubscriptions(["deenkiosk"])
        title: displayName["deenkiosk"] ?? 'DEENKIOSK',
        icon: <BaseIcon icon={Deenkiosk} />,
        path: localStorage.getItem('GetStarted') === 'Clicked' ? "/deenkiosk/devices" : "/deenkiosk",
        child: [],
        text: "New",
        disabled: detailsRequired,
      } : undefined,

      // availableSubscriptions(["deenweb"]) ? {
      //   title: displayName["Academy"] ?? 'Academy',
      //   icon: <SchoolOutlinedIcon />,
      //   path: "/education/courses-listing",
      //   child: [],
      //   disabled: detailsRequired,
      // } : undefined,

      availableSubscriptions(["deenweb"]) ? {
        title: displayName["JANAZAH"] ?? 'JANAZAH',
        icon: <SchoolOutlinedIcon />,
        path: "/janazah/janazah-requests",
        child: [],
        disabled: detailsRequired,
      } : undefined,


    ].filter((el) => el != null),


  };

  const footerItems = [
    {
      title: "Organization",
      icon: <BaseIcon icon={Organization} />,
      path: "/organization/overview",
      child: [],
      secondaryIcon: <BaseIcon icon={ChevronRight} />,
      disabled: detailsRequired,
    },
    // {
    //   title: "Admin",
    //   icon: <BaseIcon icon={Admin} />,
    //   path: "/admin-profile",
    //   child: [],
    //   disabled: detailsRequired,
    // },
    {
      title: "More Apps",
      icon: <BaseIcon icon={MoreApps} />,
      path: "/more-apps",
      child: [],
      disabled: detailsRequired,
    },
    {
      title: "Support",
      icon: <BaseIcon icon={Support} />,
      path: process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/support" : "http://deenconnect.io/support",
      child: [],
      secondaryIcon: <BaseIcon icon={Launch} />,
      disabled: detailsRequired,
    },
  ];
  const logout = () => {
    localStorage.setItem("user", false);
    localStorage.setItem("authToken", "");
    props.history.replace("/signin");
    localStorage.clear();
  };
  const userProfile = () => {
    props.history.push("/admin-profile");
  };
  const navigate = (x, y) => {
    localStorage.setItem("pageTitle", x);
    if (x === 'Support') {
      window.location.href = (y)
    } else {
      if (detailsRequired) {
        props.history.push("/dashboard");
      } else {
        props.history.push(y);
      }
    }
  };

  const getInitials = () => {
    let nameArr = localStorage.getItem("name") || (props?.signup?.fullName ?? '');
    nameArr = nameArr.length > 0 ? nameArr?.trim()?.split(" ") : [];
    let initials;
    if (nameArr?.length > 1) {
      initials = nameArr[0][0] + nameArr[1][0];
    } else {
      initials = nameArr?.[0]?.[0] + nameArr[0]?.[1] ?? '';
    }
    return initials;
  };
  let image = localStorage.getItem("image");

  const content = (
    <React.Fragment>
      <MasterLayout
        sideBar={
          <>
            <NavDrawer
              headerNavItems={headerItems.filter((el) => el != null)}
              bodyNavItems={bodyItems}
              showBodyItem={true}
              footerNavItems={footerItems}
              onClick={() => { }}
              navigate={(x, y) => navigate(x, y)}
              footerLower="footerLower"
              activeTab={returnActiveTab()}
            />
            <SessionExpiredModal />
          </>
        }
        header={
          <SimpleAppBar
            src={MainLogo}
            name={localStorage.getItem("name") || props?.signup?.name?.firstName + ' ' + props?.signup?.name?.lastName}
            role={localStorage.getItem("role")}
            showImg={image && image !== "undefined" ? true : false}
            img={image && image !== "undefined" ? image : ""}
            onClickLogout={() => logout()}
            onClickProfile={() => userProfile()}
            initials={getInitials()}
            style={{ marginTop: 80, marginLeft: -10, marginRight: '80px' }}
          />
        }
        headerFixed={true}
        middleContent={
          <Switch>
            <PrivateRoute exact path="/donations" component={DonorCollection} />
            <Route
              exact
              path="/dashboard"
              render={(props) => (
                <DashboardPage
                  {...props}
                  detailsRequired={detailsRequired}
                  stripeRequired={stripeRequired}
                  taxStatusRequired={taxStatusRequired}
                  taxIdEinRequired={taxIdEinRequired}
                  orgData={orgData}
                  loading={loading}
                />
              )}
            />
            <Route
              exact
              path="/dashboard-stats"
              render={(props) => (
                <DashboardStats
                  {...props}
                  detailsRequired={detailsRequired}
                  stripeRequired={stripeRequired}
                  taxStatusRequired={taxStatusRequired}
                  orgData={orgData}
                  loading={loading}
                />
              )}
            />
            {/* <PrivateRoute exact path="/dashboard-stats" component={DashboardStats} /> */}
            <PrivateRoute exact path="/deenkiosk" component={Dk} />
            <PrivateRoute exact path="/Announcements" component={Announcements} />
            <PrivateRoute exact path="/addannoucement" component={AddAnnoucement} />
            <PrivateRoute
              exact
              path="/editannoucement"
              component={EditAnnoucement}
            />
            <PrivateRoute exact path="/events" component={EventsComponent} />
            <PrivateRoute exact path="/addevent" component={AddEvent} />
            <PrivateRoute exact path="/editevent/:id" component={EditEvent} />
            <PrivateRoute exact path="/addreminder" component={AddReminders} />
            <PrivateRoute exact path="/editreminder" component={EditReminder} />
            <PrivateRoute exact path="/reminders" component={Managereminders} />
            <PrivateRoute exact path="/salah" component={Managesalah} />
            <PrivateRoute exact path="/Edit-profile" component={Editpofile} />
            <PrivateRoute
              exact
              path="/admin-profile"
              component={AdminProfile}
            />
            <PrivateRoute exact path="/messages" component={Messages} />
            <PrivateRoute exact path="/messages/contact-us" component={ContactUs} />
            <PrivateRoute exact path="/more-apps" component={ManageApps} />
            <PrivateRoute exact path="/app-details" component={MoreAppDetails} />
            <PrivateRoute exact path="/cart-details" component={MoreAppsCartDetails} />
            <PrivateRoute
              exact
              path="/annoucements"
              component={Manageannoucements}
            />
            <PrivateRoute exact path="/addsalah" component={AddSalah} />
            <PrivateRoute exact path="/editsalah" component={EditSalah} />
            <PrivateRoute exact path="/salahsettings" component={SalahSettings} />


            <PrivateRoute exact path="/build-project" component={BuildProject} />
            <PrivateRoute exact path="/add-testimonial" component={AddTestimonial} />
            <PrivateRoute exact path="/add-community-supporter" component={AddCommunitySupporter} />
            <PrivateRoute exact path="/organizationd-details-form" component={OrganizationDetailsForm} />
            <PrivateRoute exact path="/project-description" component={ProjectDescription} />
            <PrivateRoute exact path="/post-update" component={PostUpdate} />
            <PrivateRoute exact path="/edit-update" component={EditUpdate} />
            <PrivateRoute exact path="/add-guest" component={AddGuest} />
            <PrivateRoute exact path="/testimonial-listing" component={TestimonialListing} />
            <PrivateRoute exact path="/community-support-listing" component={CommunitySupportListing} />
            <PrivateRoute exact path="/project-highlights" component={ProjectHighlights} />
            <PrivateRoute exact path="/guests-listing" component={GuestsListing} />
            <PrivateRoute exact path="/project-phase" component={ProjectPhase} />

            <PrivateRoute exact path="/orders" component={OrgOrders} />
            <PrivateRoute exact path="/order-details/:orderId" component={OrderDetails} />
            <PrivateRoute exact path="/manage-order" component={ManageOrders} />

            <PrivateRoute exact path="/manage-subscriptions" component={ManageSubscriptions} />
            <PrivateRoute exact path="/subscriber-details/:id" component={ManageSubscriptionsDetails} />

            <PrivateRoute exact path="/media-library" component={MediaLibrary} />
            <PrivateRoute exact path="/organization/plan-details" component={PlanDetails} />



            <PrivateRoute exact path="/salat-al-jumah" component={JumahSalah} />
            <PrivateRoute exact path="/add-new-jumah" component={AddNewJumah} />
            <PrivateRoute exact path="/jumah-details" component={JumahDetails} />




          </Switch>
        }
      />
    </React.Fragment>
  );
  return (
    <>
      <BasePaper
        elevation={0}
        context={content}
        paperStyle={styles.paperStyle}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    signup: state.signup,
  };
};
export default connect(mapStateToProps, {})(withRouter(HomePage));
