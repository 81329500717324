import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import AdminIcon from '../../assets/img/Admin.svg';
import {
  BaseTextField,
  BaseButton,
  AlertAction,
  DrawerMain,
  MasterLayoutMiddle,
  FooterInner,
  FormSwitch, BaseImgChip, DrawerListRadioButton
} from "@dclab/dc-react-ui-kit";
import {
  addUserRole,
  deleteUserRole,
  editUserRole,
  deleteTeamMemberUserRole,
  editTeamMemberUserRole,
  addTeamMemberUserRole,
  emptyTeamMember,
  emptyRoles,
} from "../../redux/actions/teamMemberAction";
import { addMember } from "./api";
import "./style.css";
import "../../App.css";
import "../style.css";
import { showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
const { 
    token_expired,
    success_message_add_team_member
  } = messages_content;
class AddTeamMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      length: 0,
      expanded: false,
      addAdditioinalRole: false,
      showAddForm: false,
      showEditForm: false,
      emailError: false,
      application: "",
      role: "",
      name: "",
      email: "",
      error: false,
      addRoleError: false,
      id: "",
      access: false,
      showAlert: false,
      success: false,
      loading: false,
      clicked: false,
      memberRoles: [],
      memberRolesError: false,
      open: false,
      emailServerError: '',
      selectedData: [],
      chipData: [],
      roleData: [
        {
          id: 1,
          primary: "Administrator",
          secondary: "The Administrator has the highest access of the console after Owner.",
        },
        // {
        //   id: 2,
        //   primary: "Manager",
        //   secondary: "Best for employees who regularly create/manage/publish contents.",
        // },
      ],
      rolesDeenConnect: [
        {
          value: "Super Admin",
          label: "Super Admin",
        },
        {
          value: "Manager",
          label: "Manager",
        },
        {
          value: "Editor",
          label: "Editor",
        },
        {
          value: "Admin",
          label: "Admin",
        },
      ],
      rolesDeenFund: [
        {
          value: "Super Admin",
          label: "Super Admin",
        },
        {
          value: "Manager",
          label: "Manager",
        },
        {
          value: "Editor",
          label: "Editor",
        },
        {
          value: "Admin",
          label: "Admin",
        },
      ],
      rolesDeenConsole: [
        {
          value: "Super Admin",
          label: "Super Admin",
        },
        {
          value: "Manager",
          label: "Manager",
        },
        {
          value: "Editor",
          label: "Editor",
        },
        {
          value: "Admin",
          label: "Admin",
        },
      ],
      rolesDeenKiosk: [
        {
          value: "Super Admin",
          label: "Super Admin",
        },
        {
          value: "Manager",
          label: "Manager",
        },
        {
          value: "Editor",
          label: "Editor",
        },
        {
          value: "Admin",
          label: "Admin",
        },
      ],
      roles: [
        {
          value: "Super Admin",
          label: "Super Admin",
        },
        {
          value: "Manager",
          label: "Manager",
        },
        {
          value: "Editor",
          label: "Editor",
        },
        {
          value: "Admin",
          label: "Admin",
        },
      ],
    };
    this.addUserRole = this.addUserRole.bind(this);
    this.addMember = this.addMember.bind(this);
    this.deleteRole = this.deleteRole.bind(this);
    this.editRole = this.editRole.bind(this);
    this.editUserRole = this.editUserRole.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.onSelectApplicant = this.onSelectApplicant.bind(this)
  }
  onSelectApplicant = (e) => {
    const { roles } = this.state;
    this.setState({ role: e.target.value })
    const rolesArray = roles.filter(text => text.value !== e.target.value)
    this.setState({ roles: rolesArray })
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  handleSwitchChange = () => {
    const { access } = this.state;
    this.setState({
      access: !access,
    });
  };
  editRole = (item) => {
    this.setState({
      showEditForm: true,
      application: item[0].value,
      role: item[1].value,
      id: item[0].label,
      temp: item[0].value
    });
  };
  deleteRole = (item) => {
    const { memberRoles, rolesDeenFund, rolesDeenKiosk, rolesDeenConsole, rolesDeenConnect } = this.state;
    switch (item[0].value) {
      case 'DEENFUND':
        rolesDeenFund.push(
          {
            value: item[1].value,
            label: item[1].value,
          },
        )
        break;
      case 'DEENKIOSK':
        rolesDeenKiosk.push({
          value: item[1].value,
          label: item[1].value,
        })
      case 'DEENCONSOLE':
        rolesDeenConsole.push({
          value: item[1].value,
          label: item[1].value,
        })
      case 'DEENCONNECT':
        rolesDeenConnect.push({
          value: item[1].value,
          label: item[1].value,
        })
      default:
        break;
    }
    const newMemberRoles = memberRoles.filter((mem) => mem.id !== item[0].label);
    this.setState({ memberRoles: newMemberRoles });
  };
  editUserRole = () => {
    const { application, role, id, memberRoles } = this.state;
    if (!application || !role) {
      this.setState({ addRoleError: true });
    } else {
      const updatedMemberRoles = memberRoles.map((mem, i) => {
        if (mem.id === id) {
          memberRoles[i].application = application;
          memberRoles[i].roles = role;
          return mem;
        } else {
          return mem;
        }
      });
      this.setState({
        memberRoles: updatedMemberRoles,
        showEditForm: false,
        application: "",
        role: "",
      });
    }
  };
  addUserRole = () => {
      if(this.state.role) {
          this.setState({ 
            showAddForm: !this.state.showAddForm,
            chipData: [{ key: 0, label: this.state.role, src:AdminIcon, alt: "avatar" }]
          })
      }
  };

  addMember = async () => {
    const { name, email, access, role, emailError, emailServerError } = this.state;
    const token = localStorage.getItem("authToken");
    this.setState({
      loading: true,
      memberRolesError: false,
      error: false,
      emailError: false,
    });
    const NameSplit = name.split(/(?<=^\S+)\s/);
    if (!name || !email) {
      this.setState({ error: true, loading: false });
    } else if (emailError) {
      this.setState({
        emailError: true,
        loading: false,
      });
    } else {
      const obj = {
        name: {
          firstName: NameSplit[0],
          lastName: NameSplit[1] ? NameSplit[1] : '',
        },
        userName: email,
        role: [{ application: 'deenconnect', roles: role === "Manager" ? "manager" : "admin" }],
        orgId: localStorage.getItem("orgID"),
        access: access ? "Active" : "Revoked",
      };
      try {
        console.log(obj, "obj")
        const addMemberRes = await addMember(obj, token);
        this.setState({
          name: " ",
          email: " ",
          showAlert: true,
          success: true,
          loading: false,
        });
        this.props.showAlert(success_message_add_team_member)
        this.props.history.push({
          pathname: "/organization/overview",
          state: 1,
        });
      } catch (error) {
        this.setState({
          //error: error && error.response && error.response.data.message,
          loading: false,
          //showAlert: true,
          emailServerError: error && error.response && error.response.data.message,
        });
        setTimeout(() => {
          this.setState({error: false, emailServerError})
        }, 8000);
        this.hideAlert();
      }
    }
  };



  onInputClick = () => {
    console.log("input clicked");
  };

  onChipInputClick = () => {
    this.setState({
      showAddForm: true
    });
  };

  deleteChip = () => {
    this.setState({
      chipData: [],
      selectedData: "", role: ""
    });
  }

  getData = (value, secondary) => {
    this.setState({
      selectedData: {
        id: 3,
        primary: value,
        secondary: secondary,
    }, 
    role: value
    });
  };

  render() {
    const {
      showAddForm,
      showEditForm,
      memberRoles,
      name,
      email,
      rolesDeenConnect,
      rolesDeenConsole,
      rolesDeenFund,
      rolesDeenKiosk,
      roles,
      access,
      error,
      addRoleError,
      showAlert,
      success,
      loading,
      emailError,
      memberRolesError,
      emailServerError,
      role,
      chipData
    } = this.state;
    const col = [
      { type: "string", name: "name", label: "APPLICATION", width: '50%' },
      { type: "string", name: "category", label: "ROLE", width: '30%' },
      { id: "ACTION", label: "ACTION", width: '30%' },
    ];
    const rows = memberRoles.map((userRole) => {
      const { application, roles } = userRole;
      console.log(userRole,application,roles, 'role')
      return [
        {
          type: "string",
          name: "name",
          value: application,
          label: userRole.id,
        },
        { type: "string", name: "category", value: roles },
      ];
    });
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    const applications = [
      {
        value: "DEENFUND",
        label: "DEENFUND",
      },
      {
        value: "DEENKIOSK",
        label: "DEENKIOSK",
      },
      {
        value: "DEENCONSOLE",
        label: "DEENCONSOLE",
      },
      {
        value: "DEENCONNECT",
        label: "DEENCONNECT",
      },
    ];
    const style = {
      width: '100%',
    };
    const emailRegex = /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/;
    const disableBtn = name.length > 3 && chipData?.[0]?.label.length > 0 && emailRegex.test(email) ? false : true;
    
    return (
      <MasterLayoutMiddle
        header={
          <>
            <div className="backBtn" style={{ marginTop: 0 }}>
              <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
              <Link
                to={{
                  pathname: "/organization/overview",
                  state: 1,
                }}
                style={{
                  alignSelf: "center",
                  color: "black",
                  textDecoration: "none",
                }}
              >
                Back
          </Link>{" "}
            </div>
            {error &&
              error.response &&
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={
                    error.response.data.message === "Invalid token"
                      ? token_expired
                      : error.response.data.message
                  }
                  alertType={"error"}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            }
            {
              showAlert && error && (
                <div
                  style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={error
                    }
                    alertType={"error"}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
            {
              showAlert && success && (
                <div
                  style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={success_message_add_team_member}
                    alertType={"success"}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )
            }
          </>
        }
        isForm={true}
        formData={
          <>
            <div>
              <div>
                <h1 className='title_top'>Invite New Team Member</h1>
                <div className="dasheddBorder"></div>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseTextField
                      label="Full Name"
                      onChange={(e) => {
                        if (/^(?![\s.]+$)[a-zA-Z\s.]*$/.test(e.target.value)) {
                          this.setState({ name: e.target.value });
                        } else {
                          return false;
                        }
                      }}
                      value={name}
                      error={error && !name}
                      helperText={(error && !name && "Please enter name") || " "}
                      placeholder=" "
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseTextField
                      label="Email Address"
                      onChange={(e) => {
                        if (!emailRegex.test(e.target.value)) {
                          this.setState({
                            email: e.target.value,
                            emailError: false,
                          });
                        } else {
                          this.setState({
                            email: e.target.value,
                            emailError: false,
                          });
                        }
                      }}
                      onBlur={() => {
                        if (!emailRegex.test(email)) {
                          this.setState({
                            emailError: true,
                          });
                        } else {
                          this.setState({
                            emailError: false,
                          });
                        }
                      }}
                      value={email}
                      error={(error && !email) || emailError || emailServerError}
                      helperText={
                        (error && !email && "Please enter email") ||
                        (emailError && "Enter valid email") ||
                        (emailServerError) ||
                        " "
                      }
                      placeholder=" "
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                <div className="field_box" style={{marginTop: '20px'}} onClick={this.onChipInputClick}>
                    <BaseImgChip
                        chipData={this.state.chipData}
                        onInputClick={this.onInputClick}
                        onChipInputClick={this.onChipInputClick}
                        deleteChip={this.deleteChip}
                        label="Assign Role"
                    />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="FormInfoLine"><InfoRoundedIcon /> If the invitee doesn't have an account with DEENCONNECT, he'll get an invitation email to open account. Once he opens an account he'll become a part of your organization.</div>
                </Grid>

               
                <Grid item xs={12}>
                  {loading ? (
                    <div
                      style={{
                        marginTop: 30,
                        display: "flex",
                        justifyContent: "center",
                        width: 570,
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                      <div className="form_button">
                        <Link
                          className="cancel"
                          to={{
                            pathname: "/organization/overview",
                            state: 1,
                          }}
                          style={{
                            alignSelf: "center",
                            color: "black",
                            textDecoration: "none",
                          }}
                        >
                          CANCEL
                        </Link>
                        <BaseButton
                          text={"SEND INVITE"}
                          disabled = {disableBtn}
                          onClick={() => this.addMember()}
                        />
                      </div>
                    )}
                </Grid>
              </Grid>
            </div>
            <DrawerMain
              open={showAddForm}
              openHandler={() => this.setState({ showAddForm: false })}
              title="Assign Role"
              onCancel={() =>
                this.setState({ showAddForm: false, addRoleError: false })
              }
              onSave={() => this.addUserRole()}
            >
              <div className="mainDivSideBar">
                <div style={{ height: 75 }}>
                <DrawerListRadioButton objects={this.state.roleData} getData={this.getData} selectedData={this.state.selectedData} />
                  {/* <BaseSelect
                    id="select"
                    value={this.state.application}
                    onChange={(e) => this.setState({ application: e.target.value })}
                    object={applications}
                    style={{
                      width: 285,
                      margin: 8,
                      border: "#707070",
                      "&:focus": {
                        background: "none",
                      },
                    }}
                    label="Assign Application"
                    helperText={
                      addRoleError &&
                      !this.state.application &&
                      "Please select application"
                    }
                    error={addRoleError && !this.state.application}
                  />
                </div>
                <div style={{ height: 75 }}>
                  <BaseSelect
                    value={this.state.role}
                    onChange={
                      (e) => this.setState({ role: e.target.value })
                    }
                    object={this.state.application === 'DEENFUND' ? rolesDeenFund
                      : this.state.application === 'DEENKIOSK' ? rolesDeenKiosk
                        : this.state.application === 'DEENCONSOLE' ? rolesDeenConsole
                          : this.state.application === 'DEENCONNECT' ? rolesDeenConnect
                            : roles}
                    style={{
                      width: 285,
                      margin: 8,
                      border: "#707070",
                      "&:focus": {
                        background: "none !important",
                      },
                    }}
                    label="Role"
                    helperText={
                      addRoleError && !this.state.role && "Please select role"
                    }
                    error={addRoleError && !this.state.role}
                  /> */}
                </div>
              </div>
            </DrawerMain>
 
          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style}
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"} 
          />
        }
        isWhiteBackground={true}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    roles: state.teamMember.roles,
    teamMember: state.teamMember.editTeamMember,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addRoles: (id, application, role) =>
      dispatch(addUserRole(id, application, role)),
    deleteRoles: (application) => dispatch(deleteUserRole(application)),
    deleteTeamMemberRoles: (id) => dispatch(deleteTeamMemberUserRole(id)),
    editTeamMemberRoles: (id, application, role) =>
      dispatch(editTeamMemberUserRole(id, application, role)),
    addTeamMemberRoles: (id, application, role) =>
      dispatch(addTeamMemberUserRole(id, application, role)),
    editRole: (id, application, role) =>
      dispatch(editUserRole(id, application, role)),
    emptyTeamMember: () => dispatch(emptyTeamMember()),
    emptyRoles: () => dispatch(emptyRoles()),
    showAlert: (res) => dispatch(showAlert(res)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTeamMember);
