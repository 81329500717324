import React from 'react';
import { PageHeaderWithTabs, MasterLayoutMiddle, FooterInner, } from "@dclab/dc-react-ui-kit";

import OrderDetails from './OrderDetails';
import Students from './Students';
import Courses from './Courses';
import PaymentDetails from './PaymentDetails';



function Details(props) {

  const style = {
    width: '100%',
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(event, newValue);
  };
  const object = [
    {
      label: "ORDER DETAILS",
    },
    {
      label: "STUDENTS",
    },
    {
      label: "COURSES",
    },
    {
      label: "PAYMENT",
    },
  ];


  return (

    <MasterLayoutMiddle
      header={
        <PageHeaderWithTabs
          back={() => props.history.push("/website/pages")}
          value={value}
          title="DCED-000008"
          object={object}
          style={{ color: "#2A82F6", border: "1px solid black" }}
          handleChange={(x, y) => handleChange(x, y)}
          indicatorColor="#2A82F6"
          hiddenDraft={true}
          hiddenPublish={true}
        />
      }
      content={
        <div>
          {value === 0 && (
            <OrderDetails />

          )}

          {value === 1 && (
            <Students />
          )}

          {value === 2 && (
            <Courses />
          )}

          {value === 3 && (
            <PaymentDetails />
          )}
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />

  );
}


export default Details;