import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  BaseTextField,
  BaseSelect,
  DrawerListRadioButton,
  BaseButton,
  SimpleTable,
  BaseImgChip,
  AlertAction,
  DrawerMain,
  MasterLayoutMiddle, FooterInner,
  FormSwitch
} from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import {
  addUserRole,
  deleteUserRole,
  editUserRole,
  deleteTeamMemberUserRole,
  editTeamMemberUserRole,
  addTeamMemberUserRole,
  emptyTeamMember,
  emptyRoles,
} from "../../redux/actions/teamMemberAction";
import { editMember, getTeamMember } from "./api";
import "./style.css";
import "../../App.css";
import "../style.css";
import { showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import Tooltip from '@material-ui/core/Tooltip';

const {
  token_expired,
  success_message_edit_team_member
} = messages_content;
class EditTeamMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      length: 0,
      expanded: false,
      addAdditioinalRole: false,
      showEditForm: false,
      showAddForm: false,
      application: "",
      id: "",
      role: "",
      name: "",
      email: "",
      error: false,
      access: false,
      showAlert: false,
      success: false,
      loading: false,
      clicked: false,
      memberRoles: [],
      memberRolesError: false,
      emailError: false,
      tLoading: true,
      addRoleError: false,
      selectedData: [],
      chipData: [],
      roleData: [
        {
          id: 1,
          primary: "Admin",
          secondary: "The Administrator has the highest access of the console after Owner.",
        },
        {
          id: 2,
          primary: "Manager",
          secondary: "Best for employees who regularly create/manage/publish contents.",
        },
      ],
      rolesDeenConnect: [
        {
          value: "Super Admin",
          label: "Super Admin",
        },
        {
          value: "Manager",
          label: "Manager",
        },
        {
          value: "Editor",
          label: "Editor",
        },
        {
          value: "Admin",
          label: "Admin",
        },
      ],
      rolesDeenFund: [
        {
          value: "Super Admin",
          label: "Super Admin",
        },
        {
          value: "Manager",
          label: "Manager",
        },
        {
          value: "Editor",
          label: "Editor",
        },
        {
          value: "Admin",
          label: "Admin",
        },
      ],
      rolesDeenConsole: [
        {
          value: "Super Admin",
          label: "Super Admin",
        },
        {
          value: "Manager",
          label: "Manager",
        },
        {
          value: "Editor",
          label: "Editor",
        },
        {
          value: "Admin",
          label: "Admin",
        },
      ],
      rolesDeenKiosk: [
        {
          value: "Super Admin",
          label: "Super Admin",
        },
        {
          value: "Manager",
          label: "Manager",
        },
        {
          value: "Editor",
          label: "Editor",
        },
        {
          value: "Admin",
          label: "Admin",
        },
      ],
      roles: [
        {
          value: "Super Admin",
          label: "Super Admin",
        },
        {
          value: "Manager",
          label: "Manager",
        },
        {
          value: "Editor",
          label: "Editor",
        },
        {
          value: "Admin",
          label: "Admin",
        },
      ],
      opentooltip: false,
    };
    this.addMember = this.addMember.bind(this);
    this.deleteRole = this.deleteRole.bind(this);
    this.editRole = this.editRole.bind(this);
    this.editUserRole = this.editUserRole.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.addUserRole = this.addUserRole.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }
  async componentDidMount() {
    const pathName = this.props.history.location.pathname;
    const id = pathName.slice(pathName.lastIndexOf("/") + 1);
    const token = localStorage.getItem("authToken");
    try {
      const res = await getTeamMember(id, token);

      console.log("res Default Org: ", res)
      if(!res) {return;}
      const { name, userName, access, role, _id, defaultOrganization } = res.data.body.data;
      this.setState({
        id: _id,
        name: name.firstName + (name.lastName ? ' ' + name.lastName : ''),
        access: access?.toLowerCase() === "active" || role.find((role) => role?.roles?.toLowerCase() === "owner") ? true : false,
        email: userName,
        memberRoles: role,
        tLoading: false,
        chipData: [{ key: 0, label: role?.[0]?.roles ?? "", src: role?.[0]?.roles ?"https://www.w3schools.com/howto/img_avatar.png" : "", alt: "avatar" }],
        selectedData: this.state.roleData.filter(el => el.primary.toLowerCase() === role?.[0]?.roles.toLowerCase())[0]
      });
    } catch (error) {
      this.setState({ error, tLoading: false });
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  handleSwitchChange = () => {
    const { access, memberRoles } = this.state;
    let ownerMember = memberRoles.find((role) => role?.roles?.toLowerCase() === "owner");
    if(!ownerMember) {
      this.setState({ access: !access });
    } else {
      this.setState({ opentooltip: true });
      setTimeout(() => {
        this.setState({ opentooltip: false });
      }, 5000);
    }
  };
  editRole = (item) => {
    this.setState({
      showEditForm: true,
      application: item[0].value,
      role: item[1].value,
      id: item[0].label,
    });
  };
  deleteRole = (item) => {
    const { memberRoles } = this.state;
    const newMemberRoles = memberRoles.filter((mem) => {
      if (mem._id) {
        return mem._id !== item[0].label;
      } else if (mem.id) {
        return mem.id !== item[0].label;
      }
    });
    this.setState({
      memberRoles: newMemberRoles,
    });
  };

  addUserRole = () => {
    if(this.state.role) {
      this.setState({ 
        showAddForm: !this.state.showAddForm,
        chipData: [{ key: 0, label: this.state.role, src: "https://www.w3schools.com/howto/img_avatar.png", alt: "avatar" }]
      })
    }
    // const { application, role, memberRoles } = this.state;
    // if (!application || !role) {
    //   this.setState({ addRoleError: true });
    // } else {
    //   const fill = memberRoles.filter(text => text.application.toUpperCase() === application)
    //   if (!fill.length) {
    //     memberRoles.push({
    //       id: (Math.random() * 9).toFixed(5),
    //       application,
    //       roles: role,
    //     });
    //   } else {
    //     memberRoles.map((text, index) => {
    //       if (text.application.toUpperCase() === application) {
    //         memberRoles[index].application = application;
    //         memberRoles[index].roles = role;
    //         return text
    //       } else {
    //         return text
    //       }
    //     });
    //   }
    //   this.setState({
    //     memberRoles,
    //     application: "",
    //     role: "",
    //     showAddForm: false,
    //     memberRolesError: false,
    //   });
    // }
  };
  editUserRole = () => {
    const { application, role, id, memberRoles } = this.state;
    if (!application || !role) {
      this.setState({ addRoleError: true });
    } else {
      const updatedMemberRoles = memberRoles.map((mem, i) => {
        if (mem.id === id || mem._id === id) {
          memberRoles[i].application = application;
          memberRoles[i].roles = role;
          return mem;
        } else {
          return mem;
        }
      });
      this.setState({
        memberRoles: updatedMemberRoles,
        showEditForm: false,
        application: "",
        role: "",
        memberRolesError: false,
      });
    }
  };
  addMember = async () => {
    const pathName = this.props.history.location.pathname;
    const id = pathName.slice(pathName.lastIndexOf("/") + 1);
    const { name, email, access, role, emailError } = this.state;
    const token = localStorage.getItem("authToken");
    this.setState({
      loading: true,
      memberRolesError: false,
      error: false,
      emailError: false,
    });
    let NameSplit = name.split(/(?<=^\S+)\s/);
    console.log("NameSplit", NameSplit)
    if (!name || !email) {
      this.setState({ error: true, loading: false });
    } else if (emailError) {
      this.setState({
        emailError: true,
        loading: false,
      });
    // } else if (memberRoles.length === 0) {
    //   this.setState({ memberRolesError: true, loading: false });
    } else {
      const obj2 = {
        name: {
          firstName: NameSplit[0],
          lastName: NameSplit[1] ? NameSplit[1] : '',
        },
        userName: email,
        role: [{ application: 'deenconnect', roles: role === "Manager" ? "manager" : "admin" }],
        /* role: memberRoles, */
        access: access ? "Active" : "Revoked",
      };
      try {
       await editMember(obj2, id, token);
        this.setState({
          name: "",
          email: "",
          showAlert: true,
          success: true,
          loading: false,
        });
        this.props.showAlert(success_message_edit_team_member)
        this.props.history.push({
          pathname: "/organization/overview",
          state: 1,
        });
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          loading: false,
          showAlert: true,
        });
        this.hideAlert();
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
      }
    }
  };
  onInputClick = () => {
    console.log("input clicked");
  };

  onChipInputClick = () => {
    this.setState({
      showAddForm: true
    });
  };

  deleteChip = () => {
    this.setState({
      chipData: [],
      selectedData: "", role: ""
    });
  };
  getData = (value, secondary) => {
    if(value) {
      this.setState({
        selectedData: {
          id: 3,
          primary: value,
          secondary: secondary,
      }, 
      role: value
      });
    }
    
  };

  render() {
    const {
      showAddForm,
      name,
      email,
      showEditForm,
      access,
      error,
      rolesDeenConnect,
      rolesDeenConsole,
      rolesDeenFund,
      rolesDeenKiosk,
      roles,
      showAlert,
      success,
      loading,
      memberRoles,
      memberRolesError,
      emailError,
      tLoading,
      addRoleError,
      opentooltip
    } = this.state;
    const col = [
      { type: "string", name: "name", label: "APPLICATION", minWidth: 70 },
      { type: "string", name: "category", label: "ROLE", minWidth: 130 },
      { id: "ACTION", label: "ACTION", minWidth: 70 },
    ];
    const rows = memberRoles.map((userRole) => {
      return [
        {
          type: "string",
          name: "name",
          value: userRole.application.toUpperCase(),
          label: userRole._id || userRole.id,
        },
        {
          type: "string",
          name: "category",
          value: userRole.roles[0].toUpperCase() + userRole.roles.slice(1),
        },
      ];
    });
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    const applications = [
      {
        value: "DEENFUND",
        label: "DEENFUND",
      },
      {
        value: "DEENKIOSK",
        label: "DEENKIOSK",
      },
      {
        value: "DEENCONSOLE",
        label: "DEENCONSOLE",
      },
      {
        value: "DEENCONNECT",
        label: "DEENCONNECT",
      },
    ];
    const emailRegex = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
    const style = {
      width: '100%',
    };
    return (
      <>
        <MasterLayoutMiddle
          header={
            <div>
              {error &&
                error.response &&
                error.response.data.message === "Invalid token" && (
                  <div
                    style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}
                  >
                    <AlertAction
                      alert={
                        error.response.data.message === "Invalid token"
                          ? token_expired
                          : error.response.data.message
                      }
                      alertType={"error"}
                      onClick={() => this.setState({ error: false })}
                    />
                  </div>
                )}
              {showAlert && error && (
                <div
                  style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={error.response && error.response.data.message}
                    alertType={"error"}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
              {
                showAlert && success && (
                  <div
                    style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}
                  >
                    <AlertAction
                      alert={success_message_edit_team_member}
                      alertType={"success"}
                      onClick={() => this.setState({ showAlert: false })}
                    />
                  </div>
                )
              }
              <div className="backBtn" style={{ marginTop: 0 }}>
                <ChevronLeft
                  style={{ alignSelf: "center", marginLeft: 7 }}
                />
                <Link
                  to={{
                    pathname: "/organization/overview",
                    state: 1,
                  }}
                  style={{
                    alignSelf: "center",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  Back
                    </Link>{" "}
              </div>
            </div>
          }
          isForm={true}
          formData={
            <div>
              {tLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 350,
                  }}
                >
                  <CircularProgress color="primary" />
                </div>
              ) : (
                  <>
                    <div>
                      <h1 className="title_top">Edit Team Member</h1>
                      <div className="dasheddBorder"></div>
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            label="Full Name"
                            style={{ width: 570 }}
                            onChange={(e) => {
                              if (/^(?![\s.]+$)[a-zA-Z\s.]*$/.test(e.target.value)) {
                                this.setState({ name: e.target.value });
                              } else {
                                return false;
                              }
                            }}
                            value={name}
                            error={error && !name}
                            helperText={error && !name && "Please enter name"}
                            placeholder=" "
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            label="Email Address"
                            style={{ width: 570 }}
                            onChange={(e) => {
                              if (!emailRegex.test(e.target.value)) {
                                this.setState({
                                  email: e.target.value,
                                  emailError: false,
                                });
                              } else {
                                this.setState({
                                  email: e.target.value,
                                  emailError: false,
                                });
                              }
                            }}
                            onBlur={() => {
                              if (!emailRegex.test(email)) {
                                this.setState({
                                  emailError: true,
                                });
                              } else {
                                this.setState({
                                  emailError: false,
                                });
                              }
                            }}
                            value={email}
                            error={(error && !email) || emailError}
                            helperText={
                              (error && !email && "Please enter email") ||
                              (emailError && "Enter valid email")
                            }
                            placeholder=" "
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>

                        <div className="field_box" style={{marginTop: '20px'}} onClick={this.onChipInputClick}>
                          <BaseImgChip
                              chipData={this.state.chipData}
                              onInputClick={this.onInputClick}
                              onChipInputClick={this.onChipInputClick}
                              deleteChip={this.deleteChip}
                              label="Assign Role"
                          />
                        </div>


                        {/* <SimpleTable
                          showHeader={false}
                          showCol={true}
                          rows={rows}
                          columns={col}
                          colSpan={16}
                          actionSendButton={true}
                          classProps={changeClassProps()}
                          donationsCount={rows && rows.length > 0 ? 1 : 0}
                          noTitle="No Roles added"
                          noPara="Added Roles will be displayed and managed here"
                          noBtnText=""
                          actionLink={(name) => this.details(name)}
                          showIconCol={true}
                          editIcon={Edit}
                          deleteIcon={Delete}
                          showFooter={false}
                          deleteAction={(item) => this.deleteRole(item)}
                          editAction={(item) => this.editRole(item)}
                        /> */}
                      </Grid>
                      {/* <Grid item xs={12}>
                        <div className="field_box">
                          {memberRolesError && (
                            <span style={{ color: "red", fontSize: 12 }}>
                              You need to add user roles
                            </span>
                          )}
                          <div
                            className="addRoleDiv"
                            onClick={() =>
                              this.setState({
                                showAddForm: true,
                                application: "",
                                role: "",
                              })
                            }
                          >
                            <img
                              src={require("../../assets/img/add_circle_blue-24px.svg")}
                              alt=""
                            />
                            <span className="addRole">
                              {memberRoles.length === 0
                                ? "add user role"
                                : "add additional role"}
                            </span>
                          </div>
                        </div>
                      </Grid> */}
                      <Grid item xs={12}>
                        <div className="border_top_bottm form-switch-tooltip">
                        <Tooltip title="You cannot change owner access" arrow placement="top"
                          PopperProps={{
                            disablePortal: true,
                          }}
                          // onClose={() => setopentooltip(false)}
                          open={opentooltip}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                          <span><FormSwitch
                              headline="Member Console Access"
                              body="Allow member to login to DEENCONNECT Console or revoke access"
                              values={access}
                              handleSwitchChange={(e) => this.handleSwitchChange(e)}
                          /></span>
                        </Tooltip>
                          
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div>
                          {loading ? (
                            <div
                              style={{
                                marginTop: 30,
                                display: "flex",
                                justifyContent: "center",
                                width: 570,
                              }}
                            >
                              <CircularProgress color="primary" />
                            </div>
                          ) : (
                              <div className="form_button">
                                <Link
                                  className="cancel"
                                  to={{
                                    pathname: "/organization/overview",
                                    state: 1,
                                  }}
                                  style={{
                                    alignSelf: "center",
                                    color: "black",
                                    textDecoration: "none",
                                  }}
                                >
                                  CANCEL
                                  </Link>
                                <BaseButton
                                  text={"SAVE"}
                                  onClick={() => this.addMember()}
                                />
                              </div>
                            )}
                        </div>{" "}
                      </Grid>
                    </Grid>
                  </>
                )}
            </div>
          }
          isFooter={true}
          footer={
            <FooterInner 
              style={style} 
              termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
              privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
            />
          }
          isWhiteBackground={true}
        />
        <DrawerMain
          open={showAddForm}
          openHandler={() => this.setState({ showAddForm: false })}
          title="Application and User Role"
          onCancel={() =>
            this.setState({ showAddForm: false, addRoleError: false })
          }
          onSave={() => this.addUserRole()}
        >
          <div className="mainDivSideBar">
            <div style={{ height: 75 }}>
            <DrawerListRadioButton objects={this.state.roleData} getData={this.getData} selectedData={this.state.selectedData} />

              {/* <BaseSelect
                id="select"
                value={this.state.application}
                onChange={(e) => this.setState({ application: e.target.value })}
                object={applications}
                style={{
                  width: 285,
                  margin: 8,
                  border: "#707070",
                  "&:focus": {
                    background: "none",
                  },
                }}
                label="Assign Application"
                helperText={
                  addRoleError &&
                  !this.state.application &&
                  "Please select application"
                }
                error={addRoleError && !this.state.application}
              />
            </div>
            <div style={{ height: 75 }}>
              <BaseSelect
                value={this.state.role}
                onChange={(e) => this.setState({ role: e.target.value })}
                object={this.state.application === 'DEENFUND' ? rolesDeenFund
                  : this.state.application === 'DEENKIOSK' ? rolesDeenKiosk
                    : this.state.application === 'DEENCONSOLE' ? rolesDeenConsole
                      : this.state.application === 'DEENCONNECT' ? rolesDeenConnect
                        : roles}
                style={{
                  width: 285,
                  margin: 8,
                  border: "#707070",
                  "&:focus": {
                    background: "none !important",
                  },
                }}
                label="Role"
                helperText={
                  addRoleError && !this.state.role && "Please select role"
                }
                error={addRoleError && !this.state.role}
              />
            </div>
          </div>
        </DrawerMain>
        <DrawerMain
          open={showEditForm}
          openHandler={() => this.setState({ showEditForm: false })}
          title="Application and User Role"
          onCancel={() =>
            this.setState({ showEditForm: false, addRoleError: false })
          }
          onSave={() => this.editUserRole()}
        >
          <div className="mainDivSideBar">
            <div style={{ height: 75 }}>
              <BaseSelect
                id="select"
                value={this.state.application}
                onChange={(e) => this.setState({ application: e.target.value })}
                object={applications}
                style={{
                  width: 285,
                  margin: 8,
                  border: "#707070",
                  "&:focus": {
                    background: "none",
                  },
                }}
                label="Assign Application"
                helperText={
                  addRoleError &&
                  !this.state.application &&
                  "Please select application"
                }
                error={addRoleError && !this.state.application}
              />
            </div>
            <div style={{ height: 75 }}>
              <BaseSelect
                value={this.state.role}
                onChange={(e) => this.setState({ role: e.target.value })}
                object={this.state.application === 'DEENFUND' ? rolesDeenFund
                  : this.state.application === 'DEENKIOSK' ? rolesDeenKiosk
                    : this.state.application === 'DEENCONSOLE' ? rolesDeenConsole
                      : this.state.application === 'DEENCONNECT' ? rolesDeenConnect
                        : roles}
                style={{
                  width: 285,
                  margin: 8,
                  border: "#707070",
                  "&:focus": {
                    background: "none !important",
                  },
                }}
                label="Role"
                helperText={
                  addRoleError && !this.state.role && "Please select role"
                }
                error={addRoleError && !this.state.role}
              /> */}
            </div>
          </div>
        </DrawerMain>
        <div className="team-separator"></div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    roles: state.teamMember.roles,
    teamMember: state.teamMember.editTeamMember,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addRoles: (id, application, role) =>
      dispatch(addUserRole(id, application, role)),
    deleteRoles: (application) => dispatch(deleteUserRole(application)),
    deleteTeamMemberRoles: (id) => dispatch(deleteTeamMemberUserRole(id)),
    editTeamMemberRoles: (id, application, role) =>
      dispatch(editTeamMemberUserRole(id, application, role)),
    addTeamMemberRoles: (id, application, role) =>
      dispatch(addTeamMemberUserRole(id, application, role)),
    editRole: (id, application, role) =>
      dispatch(editUserRole(id, application, role)),
    emptyTeamMember: () => dispatch(emptyTeamMember()),
    emptyRoles: () => dispatch(emptyRoles()),
    showAlert: (res) => dispatch(showAlert(res)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditTeamMember);
