import React from 'react';
import { MasterLayoutMiddle, FooterInner, BaseSelect, BaseButton, } from "@dclab/dc-react-ui-kit";
import { DateField, TimeField ,CheckBox} from "master-react-lib";
import Grid from '@material-ui/core/Grid';
import ChevronLeft from '@material-ui/icons/ChevronLeft';


function ScheduleJanazah(props) {

    const style = {
        width: '100%',
    };


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [selectedDate, setSelectedDate] = React.useState(new Date('2022-08-18T21:11:54'));

    return (

        <MasterLayoutMiddle

            header={
                <div style={{ width: '26%', alignSelf: 'center' }}>
                    <div
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                        className='backBtn'
                    >
                        <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                        <p style={{ alignSelf: 'center' }}>Back</p>{' '}
                    </div>
                </div>
            }


            isForm={true}
            formData={
                <>
                    <div>
                        <h1 className='title_top'>Schedule Janazah</h1>
                        <div className="dasheddBorder"></div>
                    </div>


                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <DateField
                                    label="Select Date"
                                    labelStatus={true}
                                    handleDateChange={handleDateChange}
                                    selectedDate={selectedDate}

                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="field_box DateRange">
                                <TimeField
                                    label="Select Time"
                                    labelStatus={true}
                                    Value="07:00"

                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <CheckBox
                                label="Publish as announcement"
                            />
                        </Grid>




                        <Grid item xs={12}>
                            <div className="form_button">
                                <p
                                    className='cancel'
                                    onClick={() => this.onCancelOrgDetail()
                                    }
                                >
                                    GO BACK
                                </p>
                                <BaseButton text='SAVE' onClick={() => this.updateOrg()} />
                            </div>

                        </Grid>



                    </Grid>


                </>
            }

            isFooter={true}
            footer={
                <FooterInner style={style} />
            }

            isWhiteBackground={true}

        />

    );
}


export default ScheduleJanazah;