import React from 'react';
import { CardDetailsTable, } from "@dclab/dc-react-ui-kit";




function StudentDetails(props) {


    const [selectedZone, setselectedZone] = React.useState(0);

    const saveChanges = (accordian) => {
        console.log("data", accordian)
        setselectedZone(0)
    }
    const onAccordianClick = (panel) => {
        console.log("onAccordianClick", panel)
        if (panel === selectedZone) {
            setselectedZone(0)
        } else {
            setselectedZone(panel)
        }
    }

    const AccordionDetails = {
        top: "0px"
        //top:"-46px"
    };



    let header = {
        tableName: `Student`,
    };

    let data = [
        {
            field: "Name",
            value: "Hassan Ahmed"
        },

        {
            field: "Gender",
            value: "Male"
        },

        {
            field: "Date Of Birth",
            value: "Jan 12, 1980"
        },

        {
            field: "Enrolled Courses",
            value: "2"
        },

        {
            field: "Certificate",
            value: "1"
        },


    ];

    let data2 = [
        {
            field: "Parent/Guardian",
            value: "Shayan Mahmud"
        },
        {
            field: "Phone",
            value: "+1 (456) 5356 7654",
        },

        {
            field: "Email",
            value: "hassanahmed@gmail.com",
        },
        {
            field: "Address",
            value: "517 Wiseman Street Knoxville, TN 37917",
        },

    ];

    let footerLinkName = "EDIT DETAILS";
    let footerDeleteButton = "";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('/edit-detail')
    }

    const onDeleteClick = () => {
        console.log('cancel-membership')
    }


    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };

    const style = {
        width: '100%',
    };










    return (

        <CardDetailsTable
            data={data}
            data2={data2}
            twoColumns
            header={header}
            footerLinkName={footerLinkName}
            footerDeleteButton={footerDeleteButton}
            onClick={() => onClick()}
            onDeleteClick={() => onDeleteClick()}
        />



    );
}


export default StudentDetails;