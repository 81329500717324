import React from 'react';
import {
    BaseTabs,
    SimpleTable,
    AlertDialogue,
    GridContainer,
    GridItem,
    MasterLayoutMiddle,
    AlertAction,
    FooterInner
} from '@dclab/dc-react-ui-kit';

function JanazahRequests(props) {

    const style = {
        width: '100%',
    };



    // ============================== Table Start ======================================//
    let col = [

        { type: 'link', name: 'invoice_number', label: 'NAME', width: '20%' },
        { type: 'string', name: 'name', label: 'PHONE', width: '20%' },
        { type: 'string', name: 'name', label: 'EMAIL', width: '20%' },
        { type: 'string', name: 'date', label: 'JANAZAH DATE', width: '20%' },
        { type: 'string', name: 'date', label: 'STATUS', width: '20%' },
        { type: 'action', label: 'ACTION' }

    ];

    const selected_categories = col.sort((a, b) => a.order - b.order)
    let rows = selected_categories.map((text, index) => {
        let i = index + 1
        return (
            [
                { type: 'string', name: 'name', value: 'Hassan Ahmed' },
                { type: 'string', name: 'category', value: '+1 (123) 123-1234' },
                { type: 'string', name: 'frequency', value: 'hassanahmed@gmail.com' },
                { type: 'string', name: 'frequency', value: 'Apr 20, 2021' },

                { type: 'status', status: 'featured', value: 'New' },
                { type: 'action', status: 'success', value: 'see details' },

            ]
        )
    })


    // ============================== Table End ======================================//

    return (

        <MasterLayoutMiddle
            header={
                <>

                </>
            }
            content={
                <div className="table_box">
                    <SimpleTable
                        showHeader={true}
                        title="Janazah Requests"
                        width="100%"
                        rows={rows}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        donationsCount={rows && rows.length > 0 ? 1 : 0}
                        noTitle="No inquiry submitted yet"
                        noPara="Inquiries submitted by visitors will be visible and managed here."
                        noBtnText=""
                        onInviteClick={() =>
                            props.history.push(
                                "/janazah/janazah-details"
                            )
                        }
                        actionLink={(name) => props.history.push({ pathname: "/janazah/janazah-details", state: { contact: name[0].data } })}
                        showIconCol={false}
                        deleteAction={(e) => console.log(e)}
                        showFooter={true}
                        showFilter={true}
                        filterText='Filter'
                        addText="FILTER"
                        rowsPerPage={5}
                        onAddClick={() => props.history.push("/membership-details")}
                        iconTextSize={16}
                        sortColumnIndex={[4]}
                    />
                </div>
            }
            isFooter={true}
            footer={
                <FooterInner
                    style={style}
                    termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
                    privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                />
            }
            isWhiteBackground={false}
        />

    );
}


export default JanazahRequests;