import React, { useState } from 'react';
import { CardDetailsTable, AlertDialogue, CardStatus } from "@dclab/dc-react-ui-kit";

function JanazahDetailsPage(props) {
    let header = {
        tableName: `Janazah Details`,
    };

    let data = [
        {
            field: "Requested Service",
            value: "Ghusle Janabatt & Salat Al-Janazah"
        },

        {
            field: "Date",
            value: "Apr 25, 2021"
        },

        {
            field: "Time",
            value: "12:00 PM EST"
        },

        {
            field: "Make Announcement",
            value: "Yes"
        },


    ];

    let data2 = [
        {
            field: "Reporter Name",
            value: "Hassan Ahmed"
        },
        {
            field: "Phone",
            value: "+1 (456) 5356 7654",
        },

        {
            field: "Email",
            value: "hassanahmed@gmail.com",
        },
        {
            field: "Relationship To Deceased",
            value: "Sibling",
        },

    ];


    let footerLinkName = "SCHEDULE JANAZAH";
    let footerDeleteButton = "DELETE";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('/edit-detail')
    }

    const onDeleteClick = () => {
        console.log('cancel-membership')
    }

    const onClickSend = () => {
        console.log('Send')
    }


    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };



    const [open, setOpen] = useState(false);


    const handleClose = () => {
        console.log('close')
        setOpen(false)
    };
    const handleCancel = () => {
        console.log('cancel')
        setOpen(true)
    };

    const handleConfirm = () => {
        console.log('confirm')
        setOpen(false)
    };

    const style = {
        width: 470,
        marginLeft: 800
    };

    const Statustdata =[
        {value:1,  select:"Assigned"},
        {value:2,  select:"Unassigned"},
        {value:3,  select:"Completed"},
        {value:4,  select:"On hold"},
        {value:5,  select:"Canceled"},
    ];

    const Statustdata2 =[
        {value:1, statuscolor:"#7266FB", select:"New"},
        {value:2, statuscolor:"#FF9800", select:"In-progress"},
        {value:3, statuscolor:"#3CC480", select:"Completed"},
        {value:4, statuscolor:"#818E94", select:"On hold"},
        {value:5, statuscolor:"#C44058",select:"Canceled"},
    ];


    return (
        <div>
            <CardDetailsTable
                data={data}
                data2={data2}
                twoColumns
                header={header}
                footerLinkName={footerLinkName}
                footerDeleteButton={footerDeleteButton}
                onClick={() => onClick()}
                onDeleteClick={() => setOpen(true)}

                Statustdata={Statustdata}
                handleChange="handleChange"
                value="2"
                Statustdata2={Statustdata2}
                StatusDropdown={true}
                AssigneeDropdown={true}
            />

            <AlertDialogue
                title="Delete Janazah Request?"
                description="Are you sure you want to delete this janazah request? Deleting will permanently remove from everywhere."
                //description2 = "Deleting will permanently remove from Website."
                cancelText="Cancel"
                confirmText="Confirm"
                open={open}
                handleCancel={() => handleCancel()}
                handleClose={() => handleClose()}
                handleConfirm={() => handleConfirm()}
                styles={style}
            />
        </div>

    );
}


export default JanazahDetailsPage;