import React from 'react';
import {
    MasterLayoutMiddle, FooterInner, BaseRadio,
    BaseSelect, BaseTextField, BaseButton, DrawerMain, DrawerListRadioButton,
}
    from "@dclab/dc-react-ui-kit";

import Grid from '@material-ui/core/Grid';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useState } from 'react';

import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';

function AddNewCampaign(props) {



    const [Fieldvalues, fieldsetValues] = React.useState({
        name: "EID2020",
        number: "",
    });

    const fieldhandleChange = (prop) => (event) => {
        fieldsetValues({
            ...values,
            [prop]: event.target.value,
        });
    };

    const stylesField = {
        btn: {
            height: 66,
            width: "100%",
            margin: 8,
            border: "#D3D8DA",
        },
    };

    const style = {
        width: '100%',
    };

    const [values, setValues] = React.useState({
        age: "",
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const object = [
        {
            value: "1 Month",
            label: "1 Month",
        },

        {
            value: "2 Month",
            label: "2 Month",
        },

        {
            value: "3 Month",
            label: "3 Month",
        },

        {
            value: "4 Month",
            label: "4 Month",
        },
    ];

    const discountType = [
        {
            value: "Percentage",
            label: "Percentage",
        },

        {
            value: "Fixed amount",
            label: "Fixed amount",
        },

    ];

    const status = [
        {
            value: "Active",
            label: "Active",
        },

        {
            value: "Inactive",
            label: "Inactive",
        },

    ];


    

    const [isOpen, setOpen] = React.useState(false);
    const [states, setstates] = React.useState(false);
    const [temp, settemp] = React.useState('')
    const [value, setvalue] = React.useState('No specific date')

    const onSelect = (value, states) => {
        settemp(value);
        setstates(states);
    };

    const onToggle = () => {
        setOpen(!isOpen);
    };

    const clear = () => {
        setOpen(!isOpen);
        setvalue('No Specific Date')
        settemp('')

    };

    const cancel = () => {
        settemp('')
        setOpen(!isOpen);

    }
    const save = () => {
        setvalue(temp)
        setOpen(!isOpen);
    }


    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });



    const viewChange = (newValue) => {
        console.log(newValue);
    };


    const [valueEditor, setValueEditor] = useState([{ type: 'paragraph', children: [{ text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." }] }]);

    const onChange = (e) => {
        setValueEditor(e)
    };

    const styles = {
        border: '1px solid black',
        color: 'gray'
    };


    const [valueAddFromStripe, setValuesAddFromStripe] = React.useState({
        checked: false,
    });

    const handleChangeAddFromStripe = (prop) => (event) => {
        setValuesAddFromStripe({ ...values, [prop]: event.target.checked });
    };



    const [open, setOpenDrawer] = React.useState(false);
    const [valuesDrawer, setValuesDrawer] = React.useState({
        age: "",
    });

    const closeDrawer = () => {
        setOpenDrawer(!open);
    };

    const onCancel = () => {
        setOpenDrawer(false);
    };

    const onSave = () => {
        console.log("save");
    };




    const data1 = [
        {
            id: 1,
            primary: "Forever",
            secondary: "Some descriptions here",
        },
        {
            id: 2,
            primary: "Once",
            secondary: "Some descriptions here",
        },

        {
            id: 3,
            primary: "Multiple Months",
            secondary: "Some descriptions here",
        }
    ];

    const getData = (value, secondary, id) => {
        console.log(value, id);
        console.log(secondary);
    };

    const selectedData = {
        id: 4,
        primary: "Salāt al-Eid al-Adha",
        secondary: "It is possible that some time this subtitle can be two line",
    };


    return (

        <MasterLayoutMiddle

            header={
                <div style={{ width: '26%', alignSelf: 'center' }}>
                    <div
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                        className='backBtn'
                    >
                        <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                        <p style={{ alignSelf: 'center' }}>Back</p>{' '}
                    </div>
                </div>
            }


            isForm={true}
            formData={
                <>
                    <div>
                        <h1 className='title_top'>Add New Campaign</h1>
                        <div className="dasheddBorder"></div>
                    </div>


                    <Grid container spacing={3}>

                        <Grid item xs={12}>

                            <BaseRadio
                                label={"Add From Stripe"}
                                checked={valueAddFromStripe.checked}
                                onChange={handleChangeAddFromStripe("checked")}
                                helperText=""
                                subDetails=''
                            />

                            <BaseRadio
                                label={"Custom Campaign"}
                                checked={valueAddFromStripe.checked}
                                onChange={handleChangeAddFromStripe("checked")}
                                helperText=""
                                subDetails=''
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Code Name"
                                    value={Fieldvalues.name}
                                    onChange={fieldhandleChange("name")}
                                    InputProps={false}
                                    phone={false}
                                    search={false}
                                    count={1000}
                                    style={stylesField.btn}
                                    color="primary"
                                    onClick={() => console.log("onCLick")}
                                    onBlur={() => console.log("onBlur")}
                                    //helperText="Helper text"
                                    multiline={true}
                                    rowsMax={4}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Discount Type"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={discountType}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Value (%)"
                                />
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Redemption Limit"
                                    helperText="Max number of use"
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Duration"
                                    InputProps={true}
                                    onClick={() => setOpenDrawer(true)}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Select Applicable Courses"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={object}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Valid From"
                                    InputProps={true}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Valid Till"
                                    InputProps={true}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Status"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={status}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="form_button">
                                <p
                                    className='cancel'
                                    onClick={() => this.onCancelOrgDetail()
                                    }
                                >
                                    CANCEL
                                </p>
                                <BaseButton text='ADD NOW' onClick={() => this.updateOrg()} />
                            </div>

                        </Grid>



                    </Grid>


                    <DrawerMain
                        open={open}
                        openHandler={closeDrawer}
                        title="Duration"
                        onCancel={onCancel}
                        onSave={onSave}
                        loading={false}
                        saveDisabled={true}
                    >

                        <DrawerListRadioButton
                            objects={data1}
                            getData={getData}
                            selectedData={selectedData}
                            listWithImage={false}
                            style={{ width: '400px' }}
                        />

                        <div className="field_box">
                            <BaseSelect
                                label="Number of months"
                                value={values.age}
                                onChange={handleChange("age")}
                                object={object}
                            />
                        </div>


                        <div className='DrwrInfoSection'>
                            <p>For subscriptions and customers, this determines how long
                                this coupon will apply once redeemed. One-off invoices accept
                                both "once" and "forever" coupons.</p>

                            <a href=""><LaunchRoundedIcon /> Learn more</a>
                        </div>
                    </DrawerMain>


                </>
            }

            isFooter={true}
            footer={
                <FooterInner style={style} />
            }

            isWhiteBackground={true}

        />

    );
}


export default AddNewCampaign;