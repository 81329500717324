import React from 'react';
import {
    SimpleTable,
    MasterLayoutMiddle,
    FooterInner
} from '@dclab/dc-react-ui-kit';

import UsFlag from '../../../assets/img/united-states.svg';
import { useHistory } from 'react-router-dom';

function CoursesListing(props) {

    const style = {
        width: '100%',
    };

    const history = useHistory();

    // ============================== Table Start ======================================//
    let col = [

        { type: 'link', name: 'invoice_number', label: 'COURSE', width: '30%' },
        { type: 'string', name: 'name', label: 'SESSION STARTS', width: '20%' },
        { type: 'string', name: 'name', label: 'PRICE', width: '20%' },
        { type: 'string', name: 'date', label: 'STUDENTS', width: '20%' },
        { type: 'string', name: 'date', label: 'INSTRUCTOR', width: '20%' },
        { type: 'string', name: 'date', label: 'STATUS', width: '20%' },
        { type: 'action', label: 'ACTION' }

    ];

    const selected_categories = col.sort((a, b) => a.order - b.order)
    let rows = selected_categories.map((text, index) => {
        let i = index + 1
        return (
            [

                {
                    name: 'textImg',
                    type: 'string',
                    primaryText: 'Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin',
                    initials: 'HK',
                },
                { type: 'string', name: 'category', value: 'Mar 30, 2022' },
                { type: 'string', name: 'frequency', value: '$10' },
                { type: 'string', name: 'frequency', value: '100' },
                { name: 'textImg', type: 'string', src: UsFlag, },
                { type: 'status', status: 'featured', value: 'Published' },
                { type: 'action', status: 'success', value: 'see details' },

            ]
        )
    })


    // ============================== Table End ======================================//

    return (

        <MasterLayoutMiddle
            header={
                <>

                </>
            }
            content={
                <div className="table_box">
                    <SimpleTable
                        showHeader={true}
                        title="Courses"
                        width="100%"
                        rows={rows}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        donationsCount={rows && rows.length > 0 ? 1 : 0}
                        noTitle="No inquiry submitted yet"
                        noPara="Inquiries submitted by visitors will be visible and managed here."
                        noBtnText=""
                        onInviteClick={() =>
                            props.history.push(
                                "/education/course-details"
                            )
                        }
                        actionLink={(name) => props.history.push({ pathname: "/education/course-details", state: { contact: name[0].data } })}
                        showIconCol={false}
                        deleteAction={(e) => console.log(e)}
                        showFooter={true}
                        showFilter={true}
                        filterText='Filter'
                        rowsPerPage={5}
                        iconTextSize={16}
                        sortColumnIndex={[1]}
                        showAdd={true}
                        onAddClick={() => history.push("/education/add-courses")}
                        addText='ADD NEW COURSE'
                    />
                </div>
            }
            isFooter={true}
            footer={
                <FooterInner
                    style={style}
                    termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
                    privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                />
            }
            isWhiteBackground={false}
        />

    );
}


export default CoursesListing;