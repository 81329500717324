import React from 'react';
import { CardDetailsTable, Initials, CardStatus } from "@dclab/dc-react-ui-kit";

function GraveDetails (props) {
   let header = {
        tableName: `Grave Details`,
    };

    let data = [
        {
            field: "Grave Details",
            value: "Grave Purchased from {MasjidShortName}"
        },
        
        {
            field: "Name",
            value: "Hassan Ahmed"
        },
        
        {
            field: "Id/Certificate No.",
            value: "QBR01201988"
        },
        
        
    ];

    let data2 = [
        {
            field: "Date Of Birth",
            value: "Jan 20, 1988"
        },
        {
            field: "Phone",
            value: "+1 (123) 123-1234"
        },
        {
            field: "Email",
            value: "hassanahmed@email.com"
        },

    ];

    

    let footerLinkName = "";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('edit')
    }

    const onDeleteClick = () => {
        console.log('delete')
    }

    const onClickSend = () => {
        console.log('Send')
    }


    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };


    return (

        <CardDetailsTable
            data={data}
            data2={data2} 
            twoColumns 
            header={header}
            footerLinkName={footerLinkName}
            onClick={() => onClick()}
            onDeleteClick={() => onDeleteClick()}

            Status={false}
            StatusLabel="Status"
            StatusText="Active"
            statuscolor="#3CC480"

            OrgName="Deenfund"
            OrgSubText="Application"
            HeaderStatus={false}

        />

    );
}


export default GraveDetails;