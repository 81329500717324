import React from 'react';
import { CardDetailsTable, } from "@dclab/dc-react-ui-kit";




function OrderDetails(props) {


    const [selectedZone, setselectedZone] = React.useState(0);

    const saveChanges = (accordian) => {
        console.log("data", accordian)
        setselectedZone(0)
    }
    const onAccordianClick = (panel) => {
        console.log("onAccordianClick", panel)
        if (panel === selectedZone) {
            setselectedZone(0)
        } else {
            setselectedZone(panel)
        }
    }

    const AccordionDetails = {
        top: "0px"
        //top:"-46px"
    };



    let header = {
        tableName: `Order Details`,
    };

    let data = [
        {
            field: "Order #",
            value: '<a href="#">DCED-000008</a>',
        },

        {
            field: "Date",
            value: "Apr 25, 2021"
        },

        {
            field: "Students",
            value: "2"
        },

        {
            field: "Courses",
            value: "2"
        },


    ];

    let data2 = [
        {
            field: "Customer",
            value: "Hassan Ahmed"
        },
        {
            field: "Phone",
            value: "+1 (456) 5356 7654",
        },

        {
            field: "Email",
            value: "hassanahmed@gmail.com",
        },
        {
            field: "Address",
            value: "517 Wiseman Street Knoxville, TN 37917",
        },

    ];

    let footerLinkName = "INVOICE";
    let footerDeleteButton = "";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('/edit-detail')
    }

    const onDeleteClick = () => {
        console.log('cancel-membership')
    }


    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };

    const style = {
        width: '100%',
    };



    const back = () => {
        console.log("back");
    }



    const Statustdata2 = [
        { value: 1, statuscolor: "#7266FB", select: "New Order" },
        { value: 2, statuscolor: "#FF9800", select: "In-progress" },
        { value: 3, statuscolor: "#3CC480", select: "Completed" },
        { value: 4, statuscolor: "#818E94", select: "On hold" },
        { value: 5, statuscolor: "#C44058", select: "Canceled" },
    ];






    return (

        <CardDetailsTable
            data={data}
            data2={data2}
            twoColumns
            header={header}
            footerLinkName={footerLinkName}
            footerDeleteButton={footerDeleteButton}
            onClick={() => onClick()}
            onDeleteClick={() => onDeleteClick()}


            handleChange="handleChange"
            value="2"
            Statustdata2={Statustdata2}
            StatusDropdown={true}


            HeaderStatus={false}

        />



    );
}


export default OrderDetails;