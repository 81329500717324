import React from 'react';
import {
    SimpleTable,
    MasterLayoutMiddle,
    FooterInner
} from '@dclab/dc-react-ui-kit';

import Edit from "../../../assets/img/create-24px.svg";
import Delete from "../../../assets/img/delete_forever-24px.svg";
import { useHistory } from 'react-router-dom';

function Categories(props) {

    const style = {
        width: '100%',
    };

    const history = useHistory();

    // ============================== Table Start ======================================//
    let col = [

        { type: 'link', name: 'invoice_number', label: 'CATEGORY NAME', width: '50%' },
        { type: 'string', name: 'date', label: '# OF COURSES', width: '50%' },
        { type: 'string', name: 'date', label: '', width: '20%' },
        { type: 'string', name: 'date', label: '', width: '20%' },
        { type: 'action', label: 'ACTION', width: '20%' }

    ];

    const selected_categories = col.sort((a, b) => a.order - b.order)
    let rows = selected_categories.map((text, index) => {
        let i = index + 1
        return (
            [

                { type: 'string', name: 'date', value: 'Quran' },
                { type: 'status', name: 'string', value: '50' },
                { type: 'string', name: 'amount', value: '' },
                { type: 'string', name: 'amount', value: '' },

            ]
        )
    })


    // ============================== Table End ======================================//

    return (


        <MasterLayoutMiddle

            content={
                <div className="table_box">
                    <SimpleTable
                        showHeader={true}
                        title="Categories"
                        width="100%"
                        rows={rows}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        donationsCount={rows && rows.length > 0 ? 1 : 0}
                        noTitle="No inquiry submitted yet"
                        noPara="Inquiries submitted by visitors will be visible and managed here."
                        noBtnText=""
                        onInviteClick={() =>
                            props.history.push(
                                "/education/add-attendance"
                            )
                        }
                        actionLink={(name) => props.history.push({ pathname: "/education/categories/add-new-categories", state: { contact: name[0].data } })}
                        deleteAction={(e) => console.log(e)}
                        showFooter={true}
                        showIconCol={true}
                        editIcon={Edit}
                        deleteIcon={Delete}
                        showAdd={true}
                        onAddClick={() => history.push("/education/categories/add-new-categories")}
                        addText='ADD CATEGORY'

                    />
                </div>
            }
            isFooter={true}
            footer={
                <FooterInner
                    style={style}
                    termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
                    privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                />
            }
            isWhiteBackground={false}
        />



    );
}


export default Categories;