import React, { useEffect, useState } from "react";
import {
  SimpleTable,
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner, CardData
} from "@dclab/dc-react-ui-kit";
import { getOrders } from "../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../style.css";
import "../../../App.css";
import "../../style.css";
import moment from 'moment';


export default function OrgOrders(props) {
  const [orders, setorders] = useState([])
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState("")

  useEffect(() => {
    const getDetails = async () => {
      const token = localStorage.getItem("authToken");
      let orgId = localStorage.getItem("orgID");
      try {
        const ordersRes = await getOrders(orgId, token);
        console.log("ordersRes", ordersRes)
        setorders(ordersRes?.data?.body?.data)
        setloading(false);
      } catch (error) {
        seterror(error?.response && error?.response?.data?.message)
        setloading(false)
      }
    }
    getDetails();
  }, [])

  const checkStatus = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === 'new order') {
      return "active"
    } else if (status === 'in-progress') {
      return "discontinued"
    } else if (status === 'canceled') {
      return "failed"
    } else if (status === 'delivered') {
      return "success"
    } else if (status === 'on hold') {
      return "pause"
    } else {
      return "discontinued"
    }
  }


  let col = [
    { id: "RECEIPT", label: "ORDER NO.", width: "15%" },
    { id: "DATE", label: "REQUESTER", width: "15%" },
    // { type: "url", name: "single_pic", label: "ASSIGNEE", width: '10%' },
    { id: "DATE", label: "ORDER DATE", width: "20%" },
    { id: "AMOUNT", label: "AMOUNT", width: "20%" },
    { id: "STATUS", label: "STATUS", width: "10%" },
    // { id: "ACTION", label: "ACTION", width: "10%" },
  ];

  let rows = orders?.length ? orders.map((order, i) => {
    return [
      {
        type: "link",
        name: "invoice_number",
        value: order._id,
        //add url here with base url plus order-details/orderId 
        url: `/order-details/${order._id}`,  
        order: order
      },
      {
        type: "string",
        name: "date",
        value: order.requester?.firstName ? (order.requester.firstName + "") + " " + (order.requester.lastName + "") : "N/A"
      },
      // { type: "string", name: "textImg", src: "", primaryText: "", initials: "" },
      {
        type: "string",
        name: "date",
        value: order.createdAt ? moment(order.createdAt).format("lll") : "" || "N/A"
      },
      {
        type: "string",
        name: "name",
        value: order.orderItems?.[0].orderSubtotal ? "$" + (order.orderItems?.[0].orderSubtotal).toFixed(2) : "N/A",
      },
      {
        type: "status",
        status: checkStatus(order.status),
        value: order.status ? order.status[0].toUpperCase() + order.status.slice(1) : "PENDING",
      },
      // { type: "action", status: "details", value: "see details" },
    ]
  }) : [];

  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const style = {
    width: '100%',
  };

  const actionLinkHandler = (data) => {
    console.log("id is: ", data[0]);
    let id = data[0]?.order?._id;
    if (id) {
      props.history.push({ pathname: `/order-details`, state: { order: data[0].order } });
    }
  }



  return (
    <MasterLayoutMiddle
      header={
        <>
        </>
      }
      content={
        <>
          <GridContainer spacing={0} containerClass="grid-main">
            <GridItem itemStyle={{ marginTop: 0 }} itemClass="grid-item">
              <div style={{ marginBottom: "20px" }}>
                
              </div>
              <div className="speaker-simple-table">
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 30,
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                  <div className="speaker-simple-table2">
                    <SimpleTable
                      showHeader={true}
                      showCol={false}
                      title="Orders"
                      rows={rows}
                      columns={col}
                      colSpan={16}
                      actionSendButton={true}
                      classProps={changeClassProps()}
                      showAdd={true}
                      donationsCount={rows && rows.length > 0 ? 1 : 0}
                      noTitle="No Orders added"
                      noPara="Added Orders will be displayed and managed here"
                      noBtnText=""
                      actionLink={(data) => actionLinkHandler(data)}
                      showIconCol={false}
                      showFooter={rows?.length > 5 ? true : false}
                      showAdd={false}
                      iconTextSize={16}
                    />
                  </div>
                )}
              </div>
            </GridItem>
          </GridContainer>
        </>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}


