import React, { useEffect } from "react";
import { MasterLayoutMiddle, FooterInner, DateRange, SimpleTable,BaseButton } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { TimeField } from "master-react-lib";

function AddAttendance(props) {

    const style = {
        width: '100%',
    };


    const [isOpen, setOpen] = React.useState(false);
    const [states, setstates] = React.useState(false);
    const [temp, settemp] = React.useState('')
    const [value, setvalue] = React.useState('No specific date')

    const onSelect = (value, states) => {
        settemp(value);
        setstates(states);
    };

    const onToggle = () => {
        setOpen(!isOpen);
    };

    const clear = () => {
        setOpen(!isOpen);
        setvalue('No Specific Date')
        settemp('')

    };

    const cancel = () => {
        settemp('')
        setOpen(!isOpen);

    }
    const save = () => {
        setvalue(temp)
        setOpen(!isOpen);
    }




    // ============================== Table Start ======================================//
    let col = [

        { type: 'link', name: 'name', label: 'NAME', width: '40%' },
        { type: 'string', name: 'date', label: 'PRESENT', width: '18%' },
        { type: 'string', name: 'date', label: 'ABSENT', width: '18%' },
        { type: 'string', name: 'date', label: 'LATE', width: '30%' },
        { type: 'string', name: 'date', label: '', width: '20%' },


    ];

    const selected_categories = col.sort((a, b) => a.order - b.order)
    let rows = selected_categories.map((text, index) => {
        let i = index + 1
        return (
            [

                { type: 'string', name: 'name', value: 'Hassan Ahmed' },
                {
                    type: 'string', name: 'radioBox', value: "a", handleChange: function (e) {
                        console.log("onchange function called", e.target.value);
                    }, checked: "a"
                },
                {
                    type: 'string', name: 'radioBox', value: "b", handleChange: function (e) {
                        console.log("onchange function called", e.target.value);
                    }, checked: "b"
                },
                {
                    type: 'string', name: 'radioBox', value: "c", handleChange: function (e) {
                        console.log("onchange function called", e.target.value);
                    }, checked: "c"
                },
                { type: 'string', name: 'amount', value: '' },

            ]
        )
    })


    // ============================== Table End ======================================//






    return (

        <MasterLayoutMiddle



            content={
                <>



                    <Grid container spacing={3}>

                        <Grid item xs={3}>
                            <Grid item xs={12}>
                                <div className="backbtnLeft">
                                    <div
                                        onClick={() => {
                                            this.props.history.goBack();
                                        }}
                                        className='backBtn'
                                    >
                                        <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                                        <p style={{ alignSelf: 'center' }}>Back</p>{' '}
                                    </div>

                                </div>

                                <h1 className='title_top LeftTitle'>Add Attendance</h1>

                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div className="DateRageGrey">
                                        <DateRange
                                            format="MMMM DD, yyyy"
                                            title="Date"
                                            onToggle={onToggle}
                                            onSelect={onSelect}
                                            setOpen={setOpen}
                                            value={temp ? temp : value}
                                            isOpen={isOpen}
                                            clear={clear}
                                            cancel={cancel}
                                            save={save}

                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className="DateRageGrey">
                                        <TimeField
                                            label="Select Time"
                                            labelStatus={true}
                                            Value="07:00"

                                        />
                                    </div>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item xs={9}>
                            <div className="table_box">
                                <SimpleTable
                                    showHeader={true}
                                    title="Students"
                                    width="100%"
                                    rows={rows}
                                    columns={col}
                                    colSpan={16}
                                    actionSendButton={true}
                                    donationsCount={rows && rows.length > 0 ? 1 : 0}
                                    noTitle="No inquiry submitted yet"
                                    noPara="Inquiries submitted by visitors will be visible and managed here."
                                    noBtnText=""
                                    onInviteClick={() =>
                                        props.history.push(
                                            "/education/add-new-announcement"
                                        )
                                    }
                                    actionLink={(name) => props.history.push({ pathname: "/education/add-new-announcement", state: { contact: name[0].data } })}
                                    deleteAction={(e) => console.log(e)}
                                    showFooter={true}
                                    showIconCol={false}
                                    showAdd={false}
                                    addText='Add New'

                                />
                            </div>
                        </Grid>

                    </Grid>

                    <div className="FixedBtnSection">
                        <div className="form_button">
                            <p
                                className='cancel'
                                onClick={() => this.onCancelOrgDetail()
                                }
                            >
                                CANCEL
                            </p>
                            <BaseButton text='CONTINUE' onClick={() => this.updateOrg()} />
                        </div>
                    </div>


                </>
            }

            isFooter={false}
            footer={
                <FooterInner style={style} />
            }

            isWhiteBackground={false}

        />

    );
}


export default AddAttendance;