import React, { useState, useEffect } from 'react';
import { BaseSelect, DropdownMultiSelect, BaseTextField } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';



function Pricing(props) {

    const [values, setValues] = React.useState({
        age: "",
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const object = [
        {
            value: "10",
            label: "Ten",
        },
        {
            value: "20",
            label: "Twenty",
        },
        {
            value: "30",
            label: "Thirty",
        },
    ];

    const pricingType = [
        {
            value: "Paid",
            label: "Paid",
        },

    ];

    const Currency = [
        {
            value: "USD",
            label: "USD",
        },

    ];


    



    const [valuesCoupon, setValuesCoupon] = React.useState({
        name: "hello",
    });

    const handleChangeCoupon = (prop) => (event) => {
        setValuesCoupon({

        });
    };

    const viewChange = (newValue) => {
        console.log("Coming from View Change: ", newValue);
    };

    const Listdata = [
        {
            groupContacts: [],
            title: "NEWENROLLED25 (25% off)"
        },
        {
            groupContacts: [],
            title: "NEWENROLLED25 (20% off)"
        },
        {
            groupContacts: [],
            title: "NEWENROLLED25 (50% off)"
        },

    ];



    const styles = {
        select: {
            margin: 8,
            minWidth: "100%",
            height: 66,
            width: 120,
            margin: 0,
            border: "#D3D8DA",
            width: "100%",
        }
    };






    return (

        <div className='StepperFormCtn'>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <h2 className="sub_title">Course Price</h2>
                </Grid>

                <Grid item xs={12} md={12}>
                    <div className="field_box">
                        <BaseSelect
                            label="Pricing Type"
                            value={values.age}
                            onChange={handleChange("age")}
                            object={pricingType}
                            helperText="Helper text"
                            style={styles.select}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <BaseSelect
                            label="Currency"
                            value={values.age}
                            onChange={handleChange("age")}
                            object={Currency}
                            helperText="Helper text"
                            style={styles.select}
                        />
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <div className="field_box" >
                        <BaseTextField
                            label="Price"
                            placeholder=""
                            helperText=""
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <h2 className="sub_title">Coupon</h2>
                    <DropdownMultiSelect
                        label="Select coupon"
                        Listdata={Listdata.sort((a, b) => a.year - b.year)}
                        variant="standard"
                        placeholder="Search courses"
                        //helpertext="Helpertext"
                        //error="Field is required"
                        fieldToSelect='Description'
                        // variant="outlined"

                        onChange={handleChangeCoupon("name")}
                        viewChange={viewChange}

                        value={values.name}
                    />
                </Grid>


            </Grid>


        </div>

    );
}


export default Pricing;