import React from 'react';
import { SimpleTable, } from '@dclab/dc-react-ui-kit';

function StudentsListing(props) {

    const style = {
        width: '100%',
    };



    // ============================== Table Start ======================================//
    let col = [

        { type: 'string', name: 'name', label: 'NAME', width: '20%' },
        { type: 'string', name: 'date', label: 'ATTENDANCE', width: '20%' },
        { type: 'string', name: 'date', label: 'GRADE', width: '20%' },
        { type: 'string', name: 'date', label: 'ENROLLED', width: '20%' },
        { type: 'string', name: 'date', label: 'STATUS', width: '20%' },
        { type: 'action', label: 'ACTION' }

    ];

    const selected_categories = col.sort((a, b) => a.order - b.order)
    let rows = selected_categories.map((text, index) => {
        let i = index + 1
        return (
            [

                { type: 'string', name: 'name', value: 'Hassan Ahmed' },
                { type: 'string', name: 'category', value: '90%' },
                { type: 'string', name: 'frequency', value: '5th grade' },
                { type: 'string', name: 'frequency', value: 'Apr 20, 2021' },
                { type: 'status', status: 'featured', value: 'Published' },
                { type: 'action', status: 'success', value: 'see details' },

            ]
        )
    })


    // ============================== Table End ======================================//

    return (

        <SimpleTable
            showHeader={true}
            title="Students"
            width="100%"
            rows={rows}
            columns={col}
            colSpan={16}
            actionSendButton={true}
            donationsCount={rows && rows.length > 0 ? 1 : 0}
            noTitle="No inquiry submitted yet"
            noPara="Inquiries submitted by visitors will be visible and managed here."
            noBtnText=""
            onInviteClick={() =>
                props.history.push(
                    "/education/add-courses"
                )
            }
            actionLink={(name) => props.history.push({ pathname: "/education/add-courses", state: { contact: name[0].data } })}
            showIconCol={false}
            deleteAction={(e) => console.log(e)}
            showFooter={true}
            showFilter={true}
            filterText='Filter'
            addText="FILTER"
            onAddClick={() => props.history.push("/nikah/nikah-details")}
        />

    );
}


export default StudentsListing;