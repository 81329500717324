import React from 'react';
import { MasterLayoutMiddle, BaseStepper, } from "@dclab/dc-react-ui-kit";
import { ButtonOne } from "master-react-lib";
import CourseOverview from './CourseOverview';
import Learners from './Learners';
import Pricing from './Pricing';
import Publish from './Publish';

import "../Education.css"


function AddCourses(props) {


    const [activeStep, setActiveStep] = React.useState(0);
    const getSteps = () => {
        return [
            {
                number: 1, label: "Course Overview", value:
                    <div className='StepperFormContainer'>
                        <div ><CourseOverview /></div>
                    </div>
            },

            {
                number: 2, label: "Learners", value:
                    <div className='StepperFormContainer'>
                        <div><Learners /></div>
                    </div>
            },

            {
                number: 3, label: "Pricing", value:
                    <div className='StepperFormContainer'>
                        <div className='StepperTableBdy'><Pricing /></div>
                    </div>
            },

            {
                number: 4, label: "Publish", value:
                    <div className='StepperFormContainer'>
                        <div className='StepperTableBdy'><Publish /></div>
                    </div>
            },

        ];
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleBackPreviousPage = () => {
        console.log('BackButton');
    }
    const styles = {
        stepper: {
            marginTop: 120,
            padding: "8px 20px",
            borderRadius: "30px",
        },
    };

    const continueBtnClick = () => {
        console.log("Back Button Clicked");
    };

    return (
        <div className='onboardFormContainer'>
            <React.Fragment>
                <MasterLayoutMiddle
                    header={
                        <div className='SteperContainer'>
                            <BaseStepper
                                title="Add Course"
                                // subTitle="Add details of your organization to start connecting with Muslims all over the world."
                                getSteps={getSteps}
                                alternativeLabel={true}
                                handleBack={() => handleBack()}
                                handleNext={() => handleNext()}
                                handleBackPreviousPage={() => handleBackPreviousPage()}
                                nonLinear={true}
                                bottomLine={false}
                                backButton={true}
                                backgroundColor={'transparent'}
                                orientation={"horizontal"}
                                btnStyles={styles.stepper}
                                activeStep={activeStep}
                            />
                        </div>
                    }


                    isWhiteBackground={true}


                />

                <div className='OnboardFormButton'>
                    <div className='OnboardFormButtonCtn'>
                        <ButtonOne
                            label="CANCEL"
                            onClick={handleBack}
                            buttoncolor="#fff"
                            width="auto"
                            height="40px"
                            border="#ccc 0px solid"
                            borderRadius="100px"
                            color="#4d4d4d"
                            fontWeight="600"
                        />
                        <ButtonOne
                            label="CONTINUE"
                            onClick={handleNext}
                            buttoncolor="#2490F9"
                            width="auto"
                            height="40px"
                            border="#ccc 0px solid"
                            borderRadius="100px"
                            color="#fff"
                            fontWeight="600"
                        //textAlign="center"
                        //disabled="disabled"
                        />
                    </div>
                </div>

            </React.Fragment>
        </div>
    )
}


export default AddCourses;