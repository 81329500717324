import React from 'react';
import {
    SimpleTable,
    MasterLayoutMiddle,
    FooterInner
} from '@dclab/dc-react-ui-kit';

import { useHistory } from 'react-router-dom';


function OrdersListing(props) {

    const style = {
        width: '100%',
    };
    const history = useHistory();


    // ============================== Table Start ======================================//
    let col = [

        { type: 'link', name: 'invoice_number', label: 'ORDER NO.', width: '15%' },
        { type: 'string', name: 'date', label: 'ORDER DATE', width: '15%' },
        { type: 'string', name: 'frequency', label: 'CUSTOMER', width: '20%' },
        { type: 'string', name: 'frequency', label: 'ORDER STATUS', width: '15%' },
        { type: 'string', name: 'frequency', label: 'TOTAL', width: '10%' },
        { type: 'string', name: 'frequency', label: 'PAYMENT STATUS', width: '20%' },
        { type: 'action', label: 'ACTION', width: '10%' }

    ];

    const selected_categories = col.sort((a, b) => a.order - b.order)
    let rows = selected_categories.map((text, index) => {
        let i = index + 1
        return (
            [
                {
                    type: 'link',
                    name: 'invoice_number',
                    value: '1848-5131',
                    url: 'https://www.google.com',
                    linkType: "external",
                },

                { type: 'string', name: 'date', value: 'Mar 30, 2022' },
                { type: 'string', name: 'frequency', value: 'Hassan Ahmad' },
                { type: 'string', name: 'frequency', value: 'New order' },
                { type: 'status', name: 'string', value: '$500' },
                { type: 'status', name: 'string', value: 'Paid' },
                { type: 'action', status: 'success', value: 'see details' },

            ]
        )
    })


    // ============================== Table End ======================================//

    return (

        <MasterLayoutMiddle
            header={
                <>

                </>
            }
            content={
                <div className="table_box">
                    <SimpleTable
                        showHeader={true}
                        title="Orders"
                        width="100%"
                        rows={rows}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        donationsCount={rows && rows.length > 0 ? 1 : 0}
                        noTitle="No inquiry submitted yet"
                        noPara="Inquiries submitted by visitors will be visible and managed here."
                        noBtnText=""
                        onInviteClick={() =>
                            props.history.push(
                                "/education/order-details"
                            )
                        }
                        actionLink={(name) => props.history.push({ pathname: "/education/order-details", state: { contact: name[0].data } })}
                        showIconCol={false}
                        deleteAction={(e) => console.log(e)}
                        showFooter={true}
                        showFilter={true}
                        filterText='Filter'
                        showAdd={true}
                        onAddClick={() => history.push("/education/order-details")}
                        addText='NEW ORDER'
                    />
                </div>
            }
            isFooter={true}
            footer={
                <FooterInner
                    style={style}
                    termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
                    privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                />
            }
            isWhiteBackground={false}
        />

    );
}


export default OrdersListing;