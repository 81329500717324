import React from 'react';
import {
    SimpleTable,
    MasterLayoutMiddle,
    FooterInner
} from '@dclab/dc-react-ui-kit';

import Edit from "../../../assets/img/create-24px.svg";
import Delete from "../../../assets/img/delete_forever-24px.svg";
import { useHistory } from 'react-router-dom';

function Attendance(props) {

    const style = {
        width: '100%',
    };

    const history = useHistory();

    // ============================== Table Start ======================================//
    let col = [

        { type: 'link', name: 'invoice_number', label: 'DATE', width: '50%' },
        { type: 'string', name: 'date', label: 'TOTAL STUDENTS', width: '20%' },
        { type: 'string', name: 'date', label: 'PRESENT', width: '20%' },
        { type: 'string', name: 'date', label: 'ABSENT', width: '20%' },
        { type: 'action', label: 'ACTION', width: '30%' }

    ];

    const selected_categories = col.sort((a, b) => a.order - b.order)
    let rows = selected_categories.map((text, index) => {
        let i = index + 1
        return (
            [

                { type: 'string', name: 'date', value: 'Jan 20, 2020, 8:00 PM' },
                { type: 'status', name: 'string', value: '50' },
                { type: 'string', name: 'amount', value: '50' },
                { type: 'string', name: 'amount', value: '0' },

            ]
        )
    })


    // ============================== Table End ======================================//

    return (

        <div className="table_box">
            <SimpleTable
                showHeader={true}
                title="Attendance"
                width="100%"
                rows={rows}
                columns={col}
                colSpan={16}
                actionSendButton={true}
                donationsCount={rows && rows.length > 0 ? 1 : 0}
                noTitle="No inquiry submitted yet"
                noPara="Inquiries submitted by visitors will be visible and managed here."
                noBtnText=""
                onInviteClick={() =>
                    props.history.push(
                        "/education/add-attendance"
                    )
                }
                actionLink={(name) => props.history.push({ pathname: "/education/add-new-announcement", state: { contact: name[0].data } })}
                deleteAction={(e) => console.log(e)}
                showFooter={true}
                showIconCol={true}
                editIcon={Edit}
                deleteIcon={Delete}
                showAdd={true}
                onAddClick={() => history.push("/education/add-attendance")}
                addText='Add New'

            />
        </div>

    );
}


export default Attendance;