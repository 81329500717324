import React, { Component } from 'react';
import {
  BaseTextField,
  BaseButton,
  AlertAction,
  BaseFileSelect,
  BaseImgChip,
  TextEditor,
  DrawerMain, MasterLayoutMiddle, FooterInner, FormSwitch, BasePhoneInput
} from '@dclab/dc-react-ui-kit';
import MediaUpload from '../../components/MediaUpload/MediaUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
// import PhoneInput from 'react-phone-input-2';
// import {
//   BasePhoneInput
// } from 'master-react-lib';
import ReactCrop from 'react-image-crop';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Avatar from '@material-ui/core/Avatar';
import 'react-image-crop/dist/ReactCrop.css';
import Grid from '@material-ui/core/Grid';
import { getSpeaker, UpdateSpeakers } from './api';
import {
  image64toCanvasRef,
  extractImageFileExtensionFromBase64,
  base64StringtoFile,
} from '../../utils/crop';
import './style.css';
import { connect } from 'react-redux';
import { showAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';

import Verifiedicon from '../../assets/img/VerifiedIcon.svg'

const {
  token_expired,
  success_message_edit_speaker
} = messages_content;
class EditSpeaker extends Component {
  constructor(props) {
    super(props);
    this.imagePreviewCanvasRef = React.createRef();
    this.state = {
      notesCount: 0,
      fName: '',
      lName: '',
      fnameCount: 0,
      lnameCount: 0,
      nameCount: 0,
      companyNameCount: 0,
      designationCount: 0,
      id: '',
      speakerName: '',
      companyName: '',
      email: '',
      notes: '',
      designation: '',
      phoneNo: '',
      phoneNo2: '',
      available: false,
      speakerImage: [],
      image: [],
      openDrawer: false,
      showAlert: false,
      error: false,
      success: false,
      phoneError: false,
      emailError: false,
      allSpeakers: [],
      spLoading: true,
      showDrag: true,
      shortBio: [{ type: 'paragraph', children: [{ text: "" }] }],
      shortBioCount: 0,
      PhoneVerified: false,
      EmailVerified: false,
      crop: {
        aspect: 1 / 1,
      },
    };
    this.update = this.update.bind(this);
    this.upload = this.upload.bind(this);
    this.addLogo = this.addLogo.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleOnCrop = this.handleOnCrop.bind(this);
    this.handleOnCropCompleted = this.handleOnCropCompleted.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.selectMediaItem = this.selectMediaItem.bind(this);
  }

  async selectMediaItem(object) {

    this.setState({
      speakerImage: [
        {
          label: object.title,
          name: object.title,
          src: object.src,
          size: 55,
        },
      ],
      image: [
        {
          label: object.title,
          name: object.title,
          src: object.src,
          size: 55,
        },
      ],

    });

    // console.log(object.src);

  }

  async componentDidMount() {
    const pathName = this.props.history.location.pathname;
    const id = pathName.slice(pathName.lastIndexOf('/') + 1);
    const token = localStorage.getItem('authToken');
    try {
      const res = await getSpeaker(id, token);
      console.log("res: ", res)
      const {
        name,
        designation,
        status,
        imageMetaData,
        phone,
        _id,
        emailAddress,
        notes,
        companyName,
        bio,
        phoneVerified,
        emailVerified
      } = res.data.body.data;

      
      this.setState({
        id: _id,
        fName: name.firstName,
        lName: name.lastName,
        companyName: companyName ? companyName : "",
        companyNameCount: companyName ? companyName.length : "",
        nameCount: [name.firstName, name.lastName].join(' ').length,
        designation: designation.title,
        designationCount: designation.title.length,
        phoneNo: phone,
        PhoneVerified: phoneVerified,
        EmailVerified: emailVerified,
        available: status === 'Available' ? true : false,
        speakerImage: [
          { label: imageMetaData.imageName, size: imageMetaData.imageSize, name: imageMetaData.imageName, src: imageMetaData.imageUrl },
        ],
        image: [
          { label: imageMetaData.imageName, size: imageMetaData.imageSize, name: imageMetaData.imageName, src: imageMetaData.imageUrl },
        ],
        email: emailAddress,
        notes: notes.length > 2 ? [{ type: 'paragraph', children: [{ text: notes }] }] : '',
        notesCount: notes.trim().length,
        spLoading: false,
        shortBio: [{ type: 'paragraph', children: [{ text: bio ?? '' }] }],
        shortBioCount: bio ? bio.trim().length : 0
      });
      
      console.log("Email Verified: ", this.state.EmailVerified)
      console.log("Phone Verified: ", this.state.PhoneVerified)
      // console.log( this.state.speakerImage[0]);
      console.log("Speaker Data: ", res.data.body.data)
    } catch (error) {
      console.log(error);
      this.setState({ error, spLoading: false });
    }
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  changeHandler = (name, val) => {
    const { nameCount, designationCount, notesCount, companyNameCount, shortBioCount, fnameCount, lnameCount } = this.state;
    if (name === 'fname') {
      if (fnameCount < 50) {
        this.setState({ fName: val, fnameCount: val.length });
      } else {
        this.setState({ fnameCount: val.length });
      }
    } else if (name === 'lname') {
      if (lnameCount < 50) {
        this.setState({ lName: val, lnameCount: val.length });
      } else {
        this.setState({ lnameCount: val.length });
      }
    } else if (name === 'companyName') {
      if (companyNameCount < 50) {
        this.setState({ companyName: val, companyNameCount: val.length });
      } else {
        this.setState({ companyNameCount: val.length });
      }
    } else if (name === 'designation') {
      if (designationCount < 50) {
        this.setState({ designation: val, designationCount: val.length });
      } else {
        this.setState({ designationCount: val.length });
      }
    } else if (name === 'notes') {
      if (notesCount < 100) {
        this.setState({ notes: val, notesCount: val.length });
      } else {
        this.setState({ notesCount: val.length });
      }
    } else if (name === 'bio') {
      // if (shortBioCount < 100) {
      this.setState({ shortBio: val, shortBioCount: val[0].children[0].text.length });
      // } else {
      //   this.setState({ shortBioCount: val.length });
      // }
    }
  };
  handleDeleteChip = () => {
    this.setState({
      speakerImage: [],
    });
  };
  upload = async (files) => {
    if (files.length > 0) {
      files[0].label = files[0].name;
      this.setState({
        image: files,
        showDrag: files.length === 0 ? true : false,
        speakerImage: files,
      });
    }
  };

  addLogo = async () => {
    const { image } = this.state;
    if (image.length > 0) {
      const canvasRef = this.imagePreviewCanvasRef.current;
      const imgSrc = image[0].src;
      const type = imgSrc.slice(0, 5);
      let fileExtension;
      if (type === 'data:') {
        fileExtension = extractImageFileExtensionFromBase64(imgSrc);
      }
      const imageDatato64 = canvasRef.toDataURL('image/' + fileExtension);
      const myFileName = image[0].name + fileExtension;
      if (imageDatato64.split(',')[1] !== '') {
        const newCroppedFile = base64StringtoFile(imageDatato64, myFileName);
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            this.setState({
              speakerImage: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  src: reader.result,
                  name: image[0].name.slice(0, 20),
                  label: image[0].name.slice(0, 20),
                  size: image[0].size,
                },
              ],
              image: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  src: reader.result,
                  name: image[0].name.slice(0, 20),
                  label: image[0].name.slice(0, 20),
                  size: image[0].size,
                },
              ],
              openDrawer: false,
              showDrag: image.length > 0 && false,
            });
          },
          false
        );
        reader.readAsDataURL(newCroppedFile);
      } else {
        this.setState({
          speakerImage: [
            {
              label: image[0].name.slice(0, 20),
              name: image[0].name.slice(0, 20),
              src: image[0].src,
              size: image[0].size,
            },
          ],
          image: [
            {
              label: image[0].name.slice(0, 20),
              name: image[0].name.slice(0, 20),
              src: image[0].src,
              size: image[0].size,
            },
          ],
          openDrawer: false,
          showDrag: image.length > 0 && false,
        });
      }
    } else {
      this.setState({ openDrawer: false });
    }
  };
  handleOnCrop = (crop) => {
    this.setState({ crop });
    this.setState({ cropped: true });
  };
  handleOnCropCompleted = (crop, pixelCrop) => {
    const { image } = this.state;
    const canvasRef = this.imagePreviewCanvasRef.current;
    const imgSrc = image[0].src;
    image64toCanvasRef(canvasRef, imgSrc, crop);
  };
  update = async () => {
    const emailRegex = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
    const {
      id,
      fName,
      lName,
      speakerName,
      companyName,
      designation,
      phoneNo,
      available,
      speakerImage,
      email,
      notes,
      phoneError,
      shortBio,
      shortBioCount
    } = this.state;
    const Name = speakerName.split(/(?<=^\S+)\s/);
    const token = localStorage.getItem('authToken');
    let obj;
    //console.log( speakerImage );
    if (speakerImage.length > 0 && speakerImage[0].src.slice(0, 5) !== 'https') {
      console.log('inside upload section');

      obj = {
        name: {
          firstName: fName,
          lastName: lName,
        },
        designation: {
          title: designation,
        },
        imageMetaData: {
          imageUrl: speakerImage.length > 0 ? speakerImage[0].src : [],
          imageName: speakerImage.length > 0 ? speakerImage[0].name : '',
          imageThumbnail: speakerImage.length > 0 ? speakerImage[0].src : [],
          imageSize: speakerImage.length > 0 ? speakerImage[0].size : 0
        },
        status: available ? 'Available' : 'Unavailable',
        phone: phoneNo,
        emailAddress: email,
        notes: notes[0].children[0].text,
        companyName: companyName,
        bio: shortBio[0].children[0].text

      };
    }
    else {
      obj = {
        name: {
          firstName: fName,
          lastName: lName,
        },
        designation: {
          title: designation,
        },
        status: available ? 'Available' : 'Unavailable',
        phone: phoneNo,
        emailAddress: email,
        imageMetaData: {
          imageUrl: speakerImage.length > 0 ? speakerImage[0].src : '',
          imageName: speakerImage.length > 0 ? speakerImage[0].name : '',
          imageThumbnail: speakerImage.length > 0 ? speakerImage[0].src : [],
          imageSize: speakerImage.length > 0 ? speakerImage[0].size : 0
        },
        notes: notes[0].children[0].text,
        companyName: companyName,
        bio: shortBio[0].children[0].text

      }
    }
    this.setState({ loading: true, emailError: false, phoneError: false });
    if (
      !fName ||
      !lName ||
      !companyName ||
      !designation ||
      !phoneNo ||
      speakerImage.length === 0 ||
      shortBioCount === 0
    ) {
      this.setState({ error: true, loading: false });
    } else if (phoneError) {
      this.setState({ error: true, phoneError: true, loading: false });
    } else {
      try {
        
        await UpdateSpeakers(obj, token, id);
        this.setState({
          showAlert: true,
          success: true,
          loading: false,
        });
        this.props.showAlert(success_message_edit_speaker)
        this.props.history.replace('/organization/guests');
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          loading: false,
        });
        this.hideAlert();
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
      }
    }
  };
  handleRemove = () => {
    this.setState({ image: [], showDrag: true, speakerImage: [] });
  };
  render() {
    const {
      error,
      speakerName,
      companyName,
      email,
      designation,
      phoneNo,
      available,
      notes,
      success,
      showAlert,
      openDrawer,
      image,
      speakerImage,
      loading,
      phoneError,
      emailError,
      spLoading,
      showDrag,
      shortBio,
      fName,
      lName,
      crop,
      shortBioCount,
      PhoneVerified,
      EmailVerified
    } = this.state;

    console.log("EmailVerified: ", EmailVerified)
    console.log("PhoneVerified: ", PhoneVerified)
    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    const emailRegex2 = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
    const phoneRegex = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{5})$/g;
    const style = {
      width: '100%',
    };
    return (
      <>
        <MasterLayoutMiddle
          header={
            <>
              <div
                onClick={() =>
                  this.props.history.push('/organization/guests')
                }
                className='backBtn'>
                <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                <p style={{ alignSelf: 'center' }}>Back</p>{' '}
              </div>
              {(error && error === 'Invalid token') ||
                (error && error === 'No token Provided' && (
                  <div
                    style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                  >
                    <AlertAction
                      alert={token_expired}
                      alertType={'error'}
                      onClick={() => this.setState({ error: false })}
                    />
                  </div>
                ))}
              {showAlert && error && (
                <div
                  style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={
                      error === 'Invalid token'
                        ? token_expired
                        : error
                    }
                    alertType={'error'}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
              {showAlert && success && (
                <div
                  style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={success_message_edit_speaker}
                    alertType={'success'}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
            </>
          }
          isForm={true}
          formData={
            spLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 350,
                }}
              >
                <CircularProgress color='primary' />
              </div>
            ) :
              <div>
                <div>
                  <h1 className='title_top'>Edit Guests</h1>
                  <div className="dasheddBorder"></div>
                </div>
                <Grid container spacing={3}>

                  <Grid item xs={6}>
                    <div className="field_box">
                      <BaseTextField
                        label='First Name'
                        placeholder=''
                        helperText={
                          error && !fName && 'Please enter first name'
                        }
                        value={fName}
                        onChange={(e) => this.changeHandler('fname', e.target.value)}
                        error={error && !fName}
                        count={50}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <div className="field_box">
                      <BaseTextField
                        label='Last Name'
                        placeholder=''
                        helperText={
                          error && !lName && 'Please enter last name'
                        }
                        value={lName}
                        onChange={(e) => this.changeHandler('lname', e.target.value)}
                        error={error && !lName}
                        count={50}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        label='Org/Institution/Company Name'
                        placeholder=''
                        helperText={
                          error && !companyName && 'Please enter Org/Institution/Company Name'
                        }
                        value={companyName}
                        onChange={(e) => this.changeHandler('companyName', e.target.value)}
                        error={error && !companyName}
                        count={50}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        label='Designation'
                        placeholder=''
                        helperText={
                          error && !designation && 'Please enter designation'
                        }
                        value={designation}
                        onChange={(e) =>
                          this.changeHandler('designation', e.target.value)
                        }
                        error={error && !designation}
                        count={50}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="media-library-image" >
                      <MediaUpload
                        addMediaItem={this.selectMediaItem}
                        label="Guests Image"
                        buttonName="ADD IMAGE"
                        typeOfMedia='/images'
                        selectedMedia={{ logoUrl: speakerImage[0]?.src ?? "", logoName: speakerImage[0]?.name ?? "" }}
                      />
                      {/*                         <MediaUploader imageUrl={speakerImage[0].src} logoName={speakerImage[0].name} typeOfMedia='/images' label="Upload Images" onChange={this.selectMediaItem}  />
 */}                  </div>
                    <div>
                      <span style={{ color: '#f44336', fontSize: '0.75rem' }}>
                        {error &&
                          speakerImage.length === 0 &&
                          'Please provide guests image'}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box" style={{ marginTop: "15px" }}>
                      <div
                        className={
                          (error && !phoneNo) || phoneError
                            ? 'guests-secondary error'
                            : 'guests-secondary'
                        }
                      >
                        <BasePhoneInput
                          disabled={PhoneVerified}
                          phoneInputVariant={"underlineWithFlag"}
                          countryCodeEditable={false}
                          specialLabel={'Phone Number'}
                          country={'us'}
                          value={phoneNo}
                          onChange={(e, f, g, h) => {
                            if (!phoneRegex.test(e)) {
                              this.setState({ phoneError: false, phoneNo: h });
                            } else {
                              this.setState({ phoneError: false, phoneNo: h });
                            }
                          }}
                          onBlur={() => {
                            if (phoneNo.length < 14) {
                              this.setState({ phoneError: true });
                            } else {
                              this.setState({ phoneError: false });
                            }
                          }
                          }
                          errorText={error && !phoneNo ? 'Please enter phone number' : phoneError ? 'Enter valid phone number' : ' '}
                          error={error && !phoneNo ? true : phoneError ? true : false}

                          Helpertext={<div>To update the phone number, please <a href="https://shapla.deenconnect.io/about#contact-us" target="blank" >contact us</a></div>}
                          Verifiedicon={PhoneVerified ? Verifiedicon : ''}
                          verifyTooltip={PhoneVerified ? "Phone verified" : '' }
                          verifiedStatus={PhoneVerified}

                        />
                      </div>
                      {/* <div>
                        <span style={{ color: '#f44336', fontSize: '0.75rem' }}>
                          {error && !phoneNo ? 'Please enter phone number' : ''}
                          {phoneError && 'Enter valid phone number'}
                        </span>
                      </div> */}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        label='Email Address'
                        // helperText={
                        //   (emailError &&  'Enter valid email')
                        // }
                        helperText="We will send invitation to this email address"
                          Verifiedicon={EmailVerified ? Verifiedicon : ''}
                          verifyTooltip={"Email verified" }
                          verifiedStatus={EmailVerified}
                          disabled={EmailVerified}
                        style={{
                          width: 570,
                          marginLeft: 10,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                        value={email}
                        onChange={(e) => {
                          if (emailError) {
                            if (!emailRegex2.test(e.target.value)) {
                              this.setState({
                                emailError: false,
                                email: e.target.value,
                              });
                            } else {
                              this.setState({
                                emailError: false,
                                email: e.target.value,
                              });
                            }
                          } else {
                            if (!emailRegex2.test(e.target.value)) {
                              this.setState({
                                emailError: false,
                                email: e.target.value,
                              });
                            } else {
                              this.setState({
                                emailError: false,
                                email: e.target.value,
                              });
                            }
                          }
                        }}
                        onBlur={() => {
                          if (email) {
                            if (!emailRegex2.test(email)) {
                              this.setState({
                                emailError: true,
                              });
                            }
                          } else {
                            this.setState({
                              emailError: false,
                            });
                          }
                        }}
                        error={emailError}
                        placeholder=' '
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="TextArea">
                      <TextEditor
                        label='Short bio'
                        value={shortBio ?? ''}
                        onChange={(e) => this.changeHandler('bio', e)}
                        placeholder=' '
                        maxCharCount={600}
                        // helperText="Short bio about guest"
                        helperText={error && shortBioCount === 0 ? 'Please Provide guest bio' : 'Short bio about guest'}
                        error={error && shortBioCount === 0}
                      />
                    </div>
                  </Grid>

                  {notes.length > 0 && <Grid item xs={12}>
                    <div className="TextArea">
                      <TextEditor
                        label='Note From Guest'
                        value={notes ?? ''}
                        onChange={(e) => this.changeHandler('notes', e)}
                        placeholder=' '
                        count={300}
                        disabled={true}
                        // helperText="Short bio about guest"
                        helperText={error && shortBioCount === 0 ? 'Please Provide guest bio' : 'Short bio about guest'}
                        error={error && shortBioCount === 0}
                      />
                    </div>
                  </Grid>}

                  {/* <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        label='Note'
                        value={notes}
                        onChange={(e) => this.changeHandler('notes', e.target.value)}
                        placeholder=' '
                        count={100}
                      />
                    </div>
                  </Grid> */}

                  <Grid item xs={12}>
                    <div className="field_box border_top_bottm">
                      <FormSwitch
                        headline='Available'
                        body="Guest is available for events"
                        values={available}
                        handleSwitchChange={(e) => this.setState({ available: !available })}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    {loading ? (
                      <div
                        style={{
                          marginTop: 30,
                          display: 'flex',
                          justifyContent: 'center',
                          width: 570,
                        }}
                      >
                        <CircularProgress color='primary' />
                      </div>
                    ) : (
                      <div className="form_button">
                        <p className='cancel' onClick={() => this.props.history.push('/organization/guests')}> CANCEL</p>
                        <BaseButton text='SAVE' onClick={() => this.update()} />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
          }
          isFooter={true}
          footer={
            <FooterInner
              style={style}
              termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
              privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
            />
          }
          isWhiteBackground={true}
        />
        <DrawerMain
          open={openDrawer}
          openHandler={() => this.setState({ openDrawer: false })}
          title='Select Image'
          onCancel={() => this.setState({ openDrawer: false })}
          onSave={() => this.addLogo()}
        >
          <List className='img-details' style={{ paddingLeft: 0 }}>
            {image.map((file, index) => (
              <ListItem key={file.name} style={{ paddingLeft: 0 }}>
                <ListItemAvatar>
                  <Avatar
                    variant='square'
                    alt={'preview'}
                    src={
                      image.length > 0
                        ? image[0].preview || image[0].src
                        : file.preview
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={file.name.slice(0, 20)}
                  secondary={formatBytes(file.size)}
                />
                <ListItemSecondaryAction style={{ paddingLeft: 20 }}>
                  <IconButton
                    aria-label='delete'
                    size='medium'
                    style={{ padding: '0px' }}
                    onClick={() => this.handleRemove(index)}
                  >
                    <DeleteForeverIcon fontSize='inherit' />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {showDrag && (
            <>
              <div style={{ marginBottom: 20 }} className='img-uploader'>
                <BaseFileSelect selectedFiles={(file) => this.upload(file)} />
              </div>
            </>
          )}
          {image.length > 0 && image[0].src.slice(0, 5) !== 'https' && (
            <ReactCrop
              src={
                (image.length > 0 && image[0].src) ||
                (speakerImage.length > 0 && speakerImage[0].src)
              }
              crop={crop}
              onChange={this.handleOnCrop}
              onComplete={this.handleOnCropCompleted}
            />
          )}
          {(image.length > 0 || speakerImage.length > 0) && (
            <p style={{ color: '#818E94', fontSize: 12 }}>
              You can also crop the uploaded images
            </p>
          )}
          <br />
          <div style={{ display: 'none' }}>
            <canvas ref={this.imagePreviewCanvasRef}></canvas>
          </div>
        </DrawerMain>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showAlert: (res) => dispatch(showAlert(res)),
  };
};
export default connect(0, mapDispatchToProps)(EditSpeaker);