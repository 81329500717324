import React from 'react';
import { SimpleTable, } from '@dclab/dc-react-ui-kit';



function Courses(props) {

    const style = {
        width: '100%',
    };



    // ============================== Table Start ======================================//
    let col = [

        { type: 'link', name: 'frequency', label: 'COURSE', width: '25%' },
        { type: 'string', name: 'date', label: 'STUDENT', width: '45%' },
        { type: 'string', name: 'frequency', label: 'PRICE', width: '20%' },
        { type: 'action', label: 'ACTION', width: '10%' }

    ];

    const selected_categories = col.sort((a, b) => a.order - b.order)
    let rows = selected_categories.map((text, index) => {
        let i = index + 1
        return (
            [
                {
                    name: 'textImg',
                    type: 'string',
                    primaryText: 'Learn Quran in 24 hours Sheikh Muhammad Jamal Uddin',
                    initials: 'HK',
                },
                { type: 'string', name: 'frequency', value: 'Shayan Mahmud' },
                { type: 'string', name: 'date', value: '$10' },
                { type: 'action', status: 'success', value: 'see details' },

            ]
        )
    })


    // ============================== Table End ======================================//

    return (

        <div className="table_box">
            <SimpleTable
                showHeader={true}
                title="Purchase History"
                width="100%"
                rows={rows}
                columns={col}
                colSpan={16}
                actionSendButton={true}
                donationsCount={rows && rows.length > 0 ? 1 : 0}
                noTitle="No inquiry submitted yet"
                noPara="Inquiries submitted by visitors will be visible and managed here."
                noBtnText=""
                onInviteClick={() =>
                    props.history.push(
                        "/education/order-details"
                    )
                }
                actionLink={(name) => props.history.push({ pathname: "/education/order-details", state: { contact: name[0].data } })}
                showIconCol={false}
                deleteAction={(e) => console.log(e)}
                showFooter={true}
                showFilter={true}
                filterText='Filter'
                addText="FILTER"
                onAddClick={() => props.history.push("/nikah/nikah-details")}
            />
        </div>

    );
}


export default Courses;