import React, { useEffect } from "react";
import {
    MasterLayoutMiddle, FooterInner, MediaUploader,
    BaseSelect, BaseTextField, BaseButton, TextEditor, SingleDrawerDateandTime,
}
    from "@dclab/dc-react-ui-kit";

import Grid from '@material-ui/core/Grid';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useState } from 'react';

import replyIcon from '../../../assets/img/replyIcon.svg'
import FileIcon from '../../../assets/img/upload_file_black_24dp.svg'

function AddPayment(props) {

    const [dateTime, setDateTime] = React.useState();
    const getDateTime = (value) => {
        console.log(value)
    }


    const style = {
        width: '100%',
    };

    const [values, setValues] = React.useState({
        age: "",
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const object = [
        {
            value: "New York, USA",
            label: "New York, USA",
        },
    ];

    const paymentMethod = [
        {
            value: "Cash",
            label: "Cash",
        },

        {
            value: "Credit card",
            label: "Credit card",
        },

        {
            value: "Cheque",
            label: "Cheque",
        },

    ];

    const status = [
        {
            value: "Pending",  
            label: "Pending",
        },

        {
            value: "Paid",
            label: "Paid",
        },

        {
            value: "Refunded",
            label: "Refunded",
        },

    ];

    const collected = [
        {
            value: "Cash",
            label: "Cash",
        },
        {
            value: "Cheque",
            label: "Cheque",
        },
        {
            value: "Credit Card",
            label: "Credit Card",
        },
    ];


    



    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });






    const [valueEditor, setValueEditor] = useState([{ type: 'paragraph', children: [{ text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." }] }]);

    const onChange = (e) => {
        setValueEditor(e)
    };

    const styles = {
        border: '1px solid black',
        color: 'gray'
    };




    const [mediaList, setMediaList] = React.useState([]);

    const mediaObject = [{
        imageMetaData: {
            imageUrl: "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
            imageName: "Thumbnail",
            imageThumbnail: "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
            fileType: "Video",
            imageSize: 0
        },
        metaData: {
            videoUrl: "https://www.youtube.com/watch?v=6WE3nJiECRw",
            duration: "7m 16s "
        },
        _id: "610d393141e4dd5be3026723",
        isVideo: true,
        title: "Kun Faya Kun - A.R. Rahman, Mohit Chauhan [Drifting Lights x WORMONO Lofi Remake] | Bollywood Lofi",
        mediaURL: "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
        createdAt: "2021-08-06T13:29:21.978Z",
        orgId: "600aa7cd4350100e18c7370e",
        updatedAt: "2021-08-06T13:29:21.978Z"
    }]

    const getMediaItems = () => {

        setMediaList(mediaObject);

    }

    const selectMediaItem = (obj) => {
        console.log("Obj is: ", obj);

    }

    const addMediaItem = (obj) => {
        console.log("Media data is: ", obj);


    }

    const imageData = (obj) => {
        const tempMediaArray = mediaList;
        tempMediaArray.push(obj);
        setMediaList(tempMediaArray);

    }

    const videoData = (obj) => {
        console.log("Video data is: ", obj);
    }

    const onDelete = () => {
        console.log("Ondelete Called")
    }


    return (

        <MasterLayoutMiddle

            header={
                <div style={{ width: '26%', alignSelf: 'center' }}>
                    <div
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                        className='backBtn'
                    >
                        <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                        <p style={{ alignSelf: 'center' }}>Back</p>{' '}
                    </div>
                </div>
            }


            isForm={true}
            formData={
                <>
                    <div>
                        <h1 className='title_top'>Add Payment</h1>
                        <div className="dasheddBorder"></div>
                    </div>


                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Select Order"
                                // count={100}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Payment Method"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={paymentMethod}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Amount"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={object}
                                />
                            </div>
                        </Grid>


                        <Grid item xs={6}>
                            <div className="field_box">
                                <SingleDrawerDateandTime
                                    isDateorTime={true}
                                    timezone={''}
                                    Label={"Date"}
                                    getDateTime={getDateTime}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Status"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={status}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Collected by"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={collected}
                                />
                            </div>
                        </Grid>


                        <Grid item xs={12}>
                            <div className="field_box">
                                <MediaUploader
                                    onDelete={onDelete}
                                    mediaList={mediaList}
                                    getMediaItems={getMediaItems}
                                    showMediaTab={"video"}
                                    typeOfMedia='/videos'
                                    label="Photo of Check"
                                    onChange={selectMediaItem}
                                    addButtonHandler={addMediaItem}
                                    imageData={imageData}
                                    maxImageLimit={100}
                                    videoData={videoData}
                                    replyIcon={replyIcon}
                                    FileIcon={FileIcon}
                                    buttonName="ADD IMAGE"
                                    currentImageSize={1500}
                                    totalFileUploadLimit={100}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="TextArea">
                                <TextEditor
                                    onChange={(e) => onChange(e)}
                                    disabled={false}
                                    value={valueEditor}
                                    style={styles}
                                    label={"Additional information"}
                                    maxCharCount={200}
                                    placeholder="placeholder"
                                />
                            </div>
                        </Grid>


                        <Grid item xs={12}>
                            <div className="form_button">
                                <p
                                    className='cancel'
                                    onClick={() => this.onCancelOrgDetail()
                                    }
                                >
                                    CANCEL
                                </p>
                                <BaseButton text='ADD NOW' onClick={() => this.updateOrg()} />
                            </div>

                        </Grid>



                    </Grid>


                </>
            }

            isFooter={true}
            footer={
                <FooterInner style={style} />
            }

            isWhiteBackground={true}

        />

    );
}


export default AddPayment;